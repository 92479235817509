import { useCallback, useEffect, useRef, useState } from "react";
import Box from "@mui/system/Box";
import Typography from "@mui/material/Typography";
import MoneyIcon from "@mui/icons-material/Money";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  TeacherEarnings,
  GetTeacherConvStats,
} from "../redux/actions/SessionAction";
import {
  formatAMPM,
  localToUTCDate,
  utcToLocalDateConverter,
} from "../helper/sessionHelper";
import { DatePicker } from "antd";
import { Button, CircularProgress, Grid } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  styled,
} from "@material-ui/core";
import Divider from "@mui/material/Divider";
import { Select } from "antd";
import NetEarningBreakDown from "../component/Drawer/NetEarningBreakDown";
import dayjs from "dayjs";
import BonosCriteria from "../component/Drawer/BonosCriteria";
import ReferralCriteria from "../component/Drawer/ReferralCriteria";
import { useHistory } from "react-router-dom";
import MentorshipCommissionCriteria from "../component/Drawer/MentorshipCommissionCriteria";

const { RangePicker } = DatePicker;
const CustomTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#082B28",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#082B28",
    color: "#F1FCF8",
    boxShadow: theme.shadows[1],
    fontSize: 13,
    padding: 12,
    borderRadius: 4,
    fontFamily: "Inter",
    maxWidth: "none",
  },
}));
const CustomTableCell = styled(TableCell)({
  borderRight: "1px solid #D9E0DF",
});

const currencyFormatter = (amount) => {
  return new Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
    maximumFractionDigits: 0,
  }).format(amount);
};

// const commissionBracket = (conversion) => {
//   if (conversion > 15 && conversion < 18.99) {
//     return 1;
//   } else if (conversion > 19 && conversion < 21.99) {
//     return 1.5;
//   } else if (conversion > 22) {
//     return 2;
//   } else {
//     return 0;
//   }
// };

const EarningsV2 = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const teacher = useSelector((state) => state.TeacherReducer.teacher);
  const teacherStats = useSelector(
    (state) => state.TeacherReducer.conversionStats
  );

  const time_zone = JSON.parse(localStorage.getItem("TIMEZONE"));

  const currentMonth = moment().format("MMMM-YYYY");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [earningData, setEarningData] = useState();
  const [value, setValue] = useState("0");
  const [convReferralEarnings, setConvReferralEarnings] = useState(0);
  const [convProjectedEarnings, setConvProjectedEarnings] = useState(0);
  const [filterMethod, setFilterMethod] = useState("ByMonth");
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openBonosDrawer, setOpenBonosDrawer] = useState(false);
  const [openReferralDrawer, setOpenReferralDrawer] = useState(false);
  const [openMentorshipCommissionDrawer, setOpenMentorshipCommissionDrawer] =
    useState(false);
  const [loading, setLoading] = useState(true);
  const cache = useRef({});

  useEffect(() => {
    if (teacher?._id) {
      const lastDateOfMonth = moment().endOf("month").format("YYYY-MM-DD");
      const lastDateOfPreviousMonth = moment()
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
      const currentDate = moment().format("YYYY-MM-DD");

      let calcStartDate;
      let calcEndDate = moment().format("YYYY-MM-DD");
      if (lastDateOfMonth === currentDate) {
        calcStartDate = lastDateOfMonth;
      } else {
        calcStartDate = lastDateOfPreviousMonth;
      }
      setStartDate(calcStartDate);
      setEndDate(calcEndDate);
      fetchTeacherEarning(calcStartDate, calcEndDate);
    }
  }, [teacher?._id]);

  const fetchTeacherEarning = useCallback(
    (startOfMonth, endOfMonth) => {
      const cacheKey = `${startOfMonth}-${endOfMonth}`;

      const data = {
        teacherId: teacher._id,
        breakDownAfter: startOfMonth,
        endDate: endOfMonth,
      };

      const onSuccess = (data) => {
        setLoading(false);
        setEarningData(data);
        let convRefEarnings = 0;
        let convProjEarnings = 0;
        data?.referralTaken[0]?.docs?.forEach((ref) => {
          if (ref?.isDifferentTutorForDemo)
            convRefEarnings = convRefEarnings + ref?.referrerBonus;
        });
        data?.referralTaken[0]?.docs?.forEach((ref) => {
          if (ref?.isDifferentTutorForDemo)
            convProjEarnings = convProjEarnings + ref?.subscriptionAmount;
        });
        setConvReferralEarnings(convRefEarnings);
        setConvProjectedEarnings(Math.floor(convProjEarnings));

        cache.current[cacheKey] = data;
      };

      const onError = (msg) => {
        setLoading(false);
        console.log(msg);
      };

      if (cache.current[cacheKey]) {
        onSuccess(cache.current[cacheKey]);
        return;
      }
      setLoading(true);
      dispatch(TeacherEarnings(data, onSuccess, onError));
    },
    [teacher?._id, dispatch]
  );

  let referralEarning = 0;

  let commissionCriteria = {};
  let isCommissionCriteriaPassed = false;
  let isDev = false;
  if (process.env.REACT_APP_BASE_URL.includes("dev")) isDev = true;

  if (teacher?.isSalesPerson) {
    commissionCriteria = {
      minArpu: 8500,
      maxDiscountPerc: 25,
      minDemosPerMonth: isDev ? 5 : 350,
    };
    isCommissionCriteriaPassed =
      teacher?.isSalesPerson &&
      earningData?.avgOfferDiscount <= commissionCriteria.maxDiscountPerc &&
      earningData?.arpu >= commissionCriteria.maxDiscountPerc &&
      earningData?.demoAttended >= commissionCriteria.minDemosPerMonth;
  } else if (teacher?.isFluencyCounsellor) {
    commissionCriteria = {
      minDemosPerMonth: isDev ? 5 : 250,
    };
    isCommissionCriteriaPassed =
      teacher?.isFluencyCounsellor &&
      earningData?.bookedDemosCount >= commissionCriteria.minDemosPerMonth;
  }

  if (teacher?.isSalesPerson || teacher?.isFluencyCounsellor)
    referralEarning = earningData?.commission;
  else referralEarning = earningData?.totalReferralEarning;

  var bonus = 0;
  if (earningData?.totalHours >= 60 && earningData?.totalHours < 75) {
    bonus = 500;
  } else if (earningData?.totalHours >= 75 && earningData?.totalHours < 100) {
    bonus = 1000;
  } else if (earningData?.totalHours >= 100) {
    bonus = 2000;
  }

  let tds = 0;
  if (teacher?.isSalesPerson) {
    tds =
      earningData?.fixedPay +
        referralEarning +
        (earningData?.mentorConversionAmount || 0) ===
        0 ||
      earningData?.fixedPay +
        referralEarning +
        (earningData?.mentorConversionAmount || 0) -
        earningData?.totalPenalty <
        0
        ? 0
        : Math.round(
            ((earningData?.fixedPay +
              (earningData?.mentorConversionAmount || 0) +
              referralEarning -
              earningData?.totalPenalty) *
              parseInt(earningData?.TDS)) /
              100
          );
  } else {
    tds =
      earningData?.totalSessionEarning +
        referralEarning +
        (earningData?.mentorConversionAmount || 0) ===
        0 ||
      earningData?.totalSessionEarning +
        (earningData?.mentorConversionAmount || 0) +
        referralEarning -
        earningData?.totalPenalty <
        0
        ? 0
        : Math.round(
            ((earningData?.totalSessionEarning +
              referralEarning +
              (earningData?.mentorConversionAmount || 0) +
              bonus -
              earningData?.totalPenalty) *
              parseInt(earningData?.TDS)) /
              100
          );
  }

  let netEarnings = 0;
  if (teacher?.isSalesPerson) {
    netEarnings = Math.round(
      earningData?.fixedPay + referralEarning - earningData?.totalPenalty - tds
    ).toLocaleString();
  }else if(teacher?.isFluencyCounsellor){
    netEarnings = (Math.round(
      earningData?.totalSessionEarning + 
      earningData?.mentorConversionAmount + 
      referralEarning -
      (earningData?.totalPenalty + tds)
    )+bonus).toLocaleString();
  } else {
    netEarnings = (
      Math.round(
        earningData?.totalSessionEarning +
          referralEarning -
          earningData?.totalPenalty -
          tds
      ) + bonus
    ).toLocaleString();
  }

  useEffect(() => {
    if (!teacherStats && startDate && endDate) {
      const data = {
        startDate: localToUTCDate(startDate).toISOString(),
        endDate: localToUTCDate(`${endDate}T24:00`).toISOString(),
        timezone: time_zone.timezone,
      };
      const onSuccess = (res) => {};
      const onError = (err) => {};
      dispatch(GetTeacherConvStats(data, onSuccess, onError));
    }
  }, [teacherStats, startDate, endDate]);

  const hourMinuteConverter = (totalHours) => {
    if (!totalHours) {
      return `0 hour 0 minutes`;
    }
    // Extract the whole number of hours and the decimal part (minutes)
    const wholeHours = Math.floor(totalHours);
    const decimalMinutes = (totalHours - wholeHours) * 60;

    // Format the result
    const result = `${wholeHours} hours ${Math.round(decimalMinutes)} minutes`;
    return result;
  };

  const disabledDate = (current) => {
    return current && current.isAfter(moment(), "day");
  };

  function getDates(date, dateString) {
    if (date) {
      const lastDateOfPreviousMonth = moment(dateString, "MMMM-YYYY")
        .subtract(1, "months")
        .endOf("month")
        .format("YYYY-MM-DD");
      const secondLastDateOfGivenMonth = moment(dateString, "MMMM-YYYY")
        .endOf("month")
        .subtract(1, "days")
        .format("YYYY-MM-DD");

      const currentDate = moment(date).format("YYYY-MM-DD");

      let startDate, endDate;

      if (moment(dateString, "MMMM-YYYY").isBefore(moment(), "month")) {
        startDate = lastDateOfPreviousMonth;
        endDate = secondLastDateOfGivenMonth;
      } else {
        if (currentDate <= secondLastDateOfGivenMonth) {
          startDate = lastDateOfPreviousMonth;
          endDate = currentDate;
        } else {
          startDate = moment(dateString, "MMMM-YYYY")
            .endOf("month")
            .format("YYYY-MM-DD");
          endDate = currentDate;
        }
      }

      setStartDate(startDate);
      setEndDate(endDate);
    }
  }

  function getStartAndEndDates(dates, dateStrings) {
    if (dates) {
      setStartDate(dateStrings[0]);
      setEndDate(dateStrings[1]);
    }
  }

  const totalSession = earningData?.sessionTaken
    ? earningData.sessionTaken.reduce((sum, session) => {
        // Check if the speaker joining time is not empty
        if (session.speakerJoiningTime.length > 0) {
          return sum + (session.totalSessions || 0);
        }
        return sum;
      }, 0)
    : 0;

  return (
    <>
      <Box sx={{ background: "white" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 2,
            padding: 2,
          }}
        >
          <MoneyIcon />
          <Typography
            variant="h5"
            style={{
              color: "#082B28",
              fontFamily: "Inter",
              fontSize: "20px",
              fontWeight: 700,
            }}
          >
            Earnings
          </Typography>
          <CustomTooltip
            arrow
            placement="right-start"
            title="By default, earnings are displayed from the end of the previous month."
          >
            <InfoIcon style={{ color: "grey", fontSize: "20px" }} />
          </CustomTooltip>
        </Box>
        <Box
          sx={{ p: 2 }}
          component="form"
          onSubmit={(e) => {
            e.preventDefault();
            fetchTeacherEarning(startDate, endDate);
          }}
          style={{ display: "flex", gap: 10, alignItems: "center" }}
        >
          <Select
            defaultValue="ByMonth"
            style={{ width: 120 }}
            onChange={(value) => setFilterMethod(value)}
            options={[
              { value: "ByMonth", label: "By Month" },
              { value: "ByDate", label: "By Date" },
            ]}
          />
          {filterMethod === "ByMonth" && (
            <DatePicker
              picker="month"
              disabledDate={disabledDate}
              onChange={getDates}
              defaultValue={dayjs(currentMonth, "MMMM-YYYY")}
              format={"MMMM-YYYY"}
            />
          )}
          {filterMethod === "ByDate" && (
            <>
              {startDate && endDate && (
                <RangePicker
                  defaultValue={[
                    dayjs(startDate, "YYYY-MM-DD"),
                    dayjs(endDate, "YYYY-MM-DD"),
                  ]}
                  disabledDate={disabledDate}
                  onChange={getStartAndEndDates}
                />
              )}
            </>
          )}
          <Button
            type="submit"
            variant="outlined"
            disabled={loading}
            size="small"
          >
            Search
          </Button>
        </Box>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              height: "80vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Box sx={{ mx: 2, mt: 2, mb: 4 }}>
              <Grid
                container
                style={{ backgroundColor: "#D1F6EC", borderRadius: "10px" }}
              >
                <Grid item xs={12} md={2.23} sx={{ m: 1, p: 1 }}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        style={{
                          color: "#647B76",
                          fontFamily: "Inter",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "20px",
                        }}
                        sx={{ mr: 1 }}
                      >
                        Net Earnings
                      </Typography>
                      <CustomTooltip
                        arrow
                        placement="right-start"
                        title="Grand Total is the amount after deduction of TDS and penalties. To know more click on View Breakdown."
                      >
                        <InfoIcon style={{ color: "grey", fontSize: "20px" }} />
                      </CustomTooltip>
                    </Box>
                    <Typography
                      style={{
                        color: "#082B28",
                        fontFamily: "Inter",
                        fontSize: "24px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "32px",
                      }}
                      sx={{ my: 1 }}
                    >
                      ₹ {netEarnings}
                    </Typography>
                    <Typography
                      style={{
                        color: "#4A6EE0",
                        fontFamily: "Inter",
                        fontSize: "13px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => setOpenDrawer(true)}
                    >
                      View Breakdown
                    </Typography>
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2.23}
                  sx={{ m: 1, p: 1 }}
                  style={{ backgroundColor: "#F1FCF8", borderRadius: "4px" }}
                >
                  {teacher?.isSalesPerson ? (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        style={{
                          color: "#647B76",
                          fontFamily: "Inter",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "20px",
                        }}
                        sx={{ mr: 1 }}
                      >
                        Fixed Pay
                      </Typography>
                      <Typography
                        style={{
                          color: "#082B28",
                          fontFamily: "Inter",
                          fontSize: "24px",
                          fontStyle: "normal",
                          fontWeight: 700,
                          lineHeight: "32px",
                        }}
                        sx={{ my: 1 }}
                      >
                        ₹ {Math.round(earningData?.fixedPay).toLocaleString()}
                      </Typography>
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Typography
                        style={{
                          color: "#647B76",
                          fontFamily: "Inter",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "20px",
                        }}
                        sx={{ mr: 1 }}
                      >
                        Sessions Earning
                      </Typography>
                      <Typography
                        style={{
                          color: "#082B28",
                          fontFamily: "Inter",
                          fontSize: "24px",
                          fontStyle: "normal",
                          fontWeight: 700,
                          lineHeight: "32px",
                        }}
                        sx={{ my: 1 }}
                      >
                        ₹{" "}
                        {Math.round(
                          earningData?.totalSessionEarning
                        ).toLocaleString()}
                      </Typography>
                      <Box
                        sx={{
                          color: "#647B76",
                          fontFamily: "Inter",
                          fontSize: "12px",
                          fontStyle: "italic",
                          fontWeight: 400,
                          lineHeight: "16px",
                        }}
                      >
                        {hourMinuteConverter(earningData?.totalHours)}
                      </Box>
                    </Box>
                  )}
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={2.23}
                  sx={{ m: 1, p: 1 }}
                  style={{ backgroundColor: "#F1FCF8", borderRadius: "4px" }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        style={{
                          color: "#647B76",
                          fontFamily: "Inter",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "20px",
                          display: "flex",
                          alignItems: "center",
                          gap: 2,
                        }}
                        sx={{ mr: 1 }}
                      >
                        <span>Referral</span>
                        {!isCommissionCriteriaPassed &&
                        teacher?.isSalesPerson ? (
                          <>
                            <span style={{ color: "#EF4444" }}>
                              (Not Eligible)
                            </span>
                            <CustomTooltip
                              arrow
                              placement="right-start"
                              title="View criteria to check rules."
                            >
                              <InfoIcon
                                style={{ color: "grey", fontSize: "20px" }}
                              />
                            </CustomTooltip>
                          </>
                        ) : null}
                      </Typography>
                    </Box>
                    <Typography
                      style={{
                        color: "#082B28",
                        fontFamily: "Inter",
                        fontSize: "24px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "32px",
                      }}
                      sx={{ my: 1 }}
                    >
                      ₹{" "}
                      {Math.round(
                        earningData?.totalReferralEarning
                      ).toLocaleString()}
                    </Typography>
                    <Typography
                      style={{
                        color: "#4A6EE0",
                        fontFamily: "Inter",
                        fontSize: "13px",
                        fontStyle: "normal",
                        fontWeight: 500,
                        lineHeight: "20px",
                        cursor: "pointer",
                      }}
                      onClick={() => setOpenReferralDrawer(true)}
                    >
                      View Criteria
                    </Typography>
                  </Box>
                </Grid>
                {!teacher?.isSalesPerson ? (
                  <Grid
                    item
                    xs={12}
                    md={2.23}
                    sx={{ m: 1, p: 1 }}
                    style={{ backgroundColor: "#F1FCF8", borderRadius: "4px" }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box sx={{ display: "flex" }}>
                        <Typography
                          style={{
                            color: "#647B76",
                            fontFamily: "Inter",
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "20px",
                          }}
                          sx={{ mr: 1 }}
                        >
                          Bonus
                        </Typography>
                      </Box>
                      <Typography
                        style={{
                          color: "#082B28",
                          fontFamily: "Inter",
                          fontSize: "24px",
                          fontStyle: "normal",
                          fontWeight: 700,
                          lineHeight: "32px",
                        }}
                        sx={{ my: 1 }}
                      >
                        ₹ {bonus.toLocaleString()}
                      </Typography>
                      <Typography
                        style={{
                          color: "#4A6EE0",
                          fontFamily: "Inter",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: 500,
                          lineHeight: "20px",
                          cursor: "pointer",
                        }}
                        onClick={() => setOpenBonosDrawer(true)}
                      >
                        View Criteria
                      </Typography>
                    </Box>
                  </Grid>
                ) : null}
                <Grid
                  item
                  xs={12}
                  md={2.23}
                  sx={{ m: 1, p: 1 }}
                  style={{ backgroundColor: "#F1FCF8", borderRadius: "4px" }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Box sx={{ display: "flex" }}>
                      <Typography
                        style={{
                          color: "#647B76",
                          fontFamily: "Inter",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "20px",
                        }}
                        sx={{ mr: 1 }}
                      >
                        Penalties
                      </Typography>
                    </Box>
                    <Typography
                      style={{
                        color: "#EF4444",
                        fontFamily: "Inter",
                        fontSize: "24px",
                        fontStyle: "normal",
                        fontWeight: 700,
                        lineHeight: "32px",
                      }}
                      sx={{ my: 1 }}
                    >
                      -₹{" "}
                      {Math.round(earningData?.totalPenalty).toLocaleString()}
                    </Typography>
                  </Box>
                </Grid>

                {teacher?.isSalesPerson || teacher?.isFluencyCounsellor ? (
                  <Grid
                    item
                    xs={12}
                    md={2.23}
                    sx={{ m: 1, p: 1 }}
                    style={{ backgroundColor: "#F1FCF8", borderRadius: "4px" }}
                  >
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box sx={{ display: "flex" }}>
                        <Typography
                          style={{
                            color: "#647B76",
                            fontFamily: "Inter",
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: 600,
                            lineHeight: "20px",
                          }}
                          sx={{ mr: 1 }}
                        >
                          Revenue Generated
                        </Typography>
                      </Box>
                      <Typography
                        style={{
                          color: "#082B28",
                          fontFamily: "Inter",
                          fontSize: "24px",
                          fontStyle: "normal",
                          fontWeight: 700,
                          lineHeight: "32px",
                        }}
                        sx={{ my: 1 }}
                      >
                        ₹{" "}
                        {Math.round(
                          earningData?.revenueGenerated
                        ).toLocaleString()}
                      </Typography>
                    </Box>
                  </Grid>
                ) : null}
              </Grid>
            </Box>

            <TabContext value={value}>
              <TabList
                onChange={(event, newValue) => setValue(newValue)}
                aria-label="basic tabs example"
                sx={{
                  background: "#F1FCF8",
                  borderBottom: "0.5px solid #D9E0DF",
                  borderTop: "0.5px solid #D9E0DF",
                }}
              >
                {earningData?.sessionTaken?.map((data, i) => (
                  <Tab
                    label={
                      data.type === "Demo" &&
                      data?.speakerJoiningTime.length === 0
                        ? data.forKids === false
                          ? `${data.subject} ${data.type} ${data.sessionDuration} (Cancelled within 1hr)`
                          : `Kids ${data.type} ${data.sessionDuration}`
                        : data.forKids === false
                        ? `${data.subject} ${data.type} ${data.sessionDuration}`
                        : `Kids ${data.type} ${data.sessionDuration}`
                    }
                    value={`${i}`}
                    sx={{
                      textTransform: "capitalize",
                      color: "#637381",
                      fontFamily: "Inter",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "20px",
                    }}
                    key={i}
                  />
                ))}
                {earningData?.reassignSessions.length > 0 && (
                  <Tab
                    label={"Cancelled Sessions"}
                    value="cancelledSessions"
                    sx={{
                      textTransform: "capitalize",
                      color: "#637381",
                      fontFamily: "Inter",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "20px",
                    }}
                  />
                )}
                {earningData?.referralTaken.length > 0 && (
                  <Tab
                    label="Referral"
                    value="Referral"
                    sx={{
                      textTransform: "capitalize",
                      color: "#637381",
                      fontFamily: "Inter",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "20px",
                    }}
                  />
                )}

                {earningData?.menteesData?.menteesData?.length > 0 && (
                  <Tab
                    label="Mentees"
                    value="Mentees"
                    sx={{
                      textTransform: "capitalize",
                      color: "#637381",
                      fontFamily: "Inter",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "20px",
                    }}
                  />
                )}
              </TabList>

              {earningData?.sessionTaken?.map((data, i) => (
                <TabPanel value={`${i}`} sx={{ m: 0, p: 0 }} key={i}>
                  <Box sx={{ display: "flex", p: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        sx={{
                          color: "#647B76",
                          fontFamily: "Inter",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "20px",
                          mb: 1,
                        }}
                      >
                        Pay/session
                      </Box>
                      <Box
                        sx={{
                          color: "#082B28",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "24px",
                        }}
                      >
                        ₹ {data.payForEachSession}
                      </Box>
                    </Box>

                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ ml: 6, mr: 2 }}
                    />

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        sx={{
                          color: "#647B76",
                          fontFamily: "Inter",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "20px",
                          mb: 1,
                        }}
                      >
                        From
                      </Box>
                      <Box
                        sx={{
                          color: "#082B28",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "24px",
                        }}
                      >
                        {data.totalSessions} sessions{" "}
                        {data._id.subjects === "English" &&
                        data._id.type === "Demo" &&
                        data._id.sessionDuration === "15"
                          ? `(₹70 for week days and ₹80 for weekends)`
                          : ""}
                      </Box>
                    </Box>

                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ ml: 6, mr: 2 }}
                    />

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        sx={{
                          color: "#647B76",
                          fontFamily: "Inter",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "20px",
                          mb: 1,
                        }}
                      >
                        You Earned
                      </Box>
                      <Box
                        sx={{
                          color: "#082B28",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "24px",
                        }}
                      >
                        ₹ {data.earnings.toLocaleString()}
                      </Box>
                    </Box>
                  </Box>
                  <TableContainer>
                    <Table aria-label="a dense table">
                      <TableHead>
                        <TableRow style={{ backgroundColor: "#F2F5F4" }}>
                          <CustomTableCell>Session Date</CustomTableCell>
                          <CustomTableCell>Session Start Time</CustomTableCell>
                          <CustomTableCell>Session End Time</CustomTableCell>
                          <TableCell>Speaker Joining Time</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {data.sessionsData.map((session, i) => (
                          <TableRow key={i}>
                            <CustomTableCell>
                              {utcToLocalDateConverter(
                                session.sessionDate,
                                session.endTime
                              )}

                              {session.type === "Demo" &&
                              session?.speakerJoiningTime.length === 0 ? (
                                <span style={{ fontWeight: "bold" }}>
                                  {" "}
                                  (Cancelled within 1hr){" "}
                                </span>
                              ) : (
                                " "
                              )}
                            </CustomTableCell>
                            <CustomTableCell>
                              {formatAMPM(session.startTime)}
                            </CustomTableCell>
                            <CustomTableCell>
                              {formatAMPM(session.endTime)}
                            </CustomTableCell>
                            <TableCell>
                              {session.speakerJoiningTime.length > 0
                                ? formatAMPM(session.speakerJoiningTime)
                                : "N/A"}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TabPanel>
              ))}

              {earningData?.reassignSessions?.length > 0 && (
                <TabPanel value="cancelledSessions" sx={{ m: 0, p: 0 }}>
                  <TableContainer>
                    <Table aria-label="a dense table">
                      <TableHead>
                        <TableRow style={{ backgroundColor: "#F2F5F4" }}>
                          <CustomTableCell>Learner Name</CustomTableCell>
                          <CustomTableCell>Session Time</CustomTableCell>
                          <CustomTableCell>Session Type</CustomTableCell>
                          <TableCell>Session Date</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {earningData?.reassignSessions?.map((session, i) => (
                          <TableRow key={i}>
                            <CustomTableCell>
                              {session?.learner?.name || "N/A"}
                            </CustomTableCell>
                            <CustomTableCell>{`${formatAMPM(
                              session?.startTime
                            )}-${formatAMPM(
                              session?.endTime
                            )}`}</CustomTableCell>
                            <CustomTableCell>{session?.type}</CustomTableCell>
                            <TableCell>
                              {moment(new Date(session?.sessionDate)).format(
                                "YYYY-MM-DD"
                              )}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TabPanel>
              )}

              {earningData?.referralTaken.length > 0 && (
                <TabPanel value="Referral" sx={{ m: 0, p: 0 }}>
                  <Box sx={{ display: "flex", p: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        sx={{
                          color: "#647B76",
                          fontFamily: "Inter",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "20px",
                          mb: 1,
                        }}
                      >
                        Referral Earning
                      </Box>
                      <Box
                        sx={{
                          color: "#082B28",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "24px",
                        }}
                      >
                        ₹{" "}
                        {earningData?.referralTaken[0]?.referralEarning
                          ?.toFixed()
                          .toLocaleString()}
                      </Box>
                    </Box>
                  </Box>

                  <TableContainer>
                    <Table aria-label="a dense table">
                      <TableHead>
                        <TableRow style={{ backgroundColor: "#F2F5F4" }}>
                          <CustomTableCell>Referree Name</CustomTableCell>
                          <CustomTableCell>Referrer Name</CustomTableCell>
                          <CustomTableCell>Referree Bonus</CustomTableCell>
                          <CustomTableCell>Referrer Bonus</CustomTableCell>
                          <CustomTableCell>Subscription Amount</CustomTableCell>
                          <CustomTableCell>Referrer Type</CustomTableCell>
                          <CustomTableCell>Date of Demo</CustomTableCell>
                          <CustomTableCell>Date of purchase</CustomTableCell>
                          <TableCell>Conversion Referral</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {earningData?.referralTaken[0]?.docs?.map(
                          (refer, i) => (
                            <TableRow key={i}>
                              <CustomTableCell>
                                {refer.referreeName}
                              </CustomTableCell>
                              <CustomTableCell>
                                {refer.referrerName}
                              </CustomTableCell>
                              <CustomTableCell>
                                {Math.round(
                                  refer.referreeBonus
                                ).toLocaleString()}
                              </CustomTableCell>
                              <CustomTableCell>
                                {Math.round(
                                  refer.referrerBonus
                                ).toLocaleString()}
                              </CustomTableCell>
                              <CustomTableCell>
                                {refer.subscriptionAmount.toLocaleString()}
                              </CustomTableCell>
                              <CustomTableCell>
                                {refer.referrerType}
                              </CustomTableCell>

                              {refer.DemoDate !== "null" ||
                              refer.DemoDate !== "N/A" ? (
                                <CustomTableCell>
                                  {moment(refer.DemoDate)
                                    .utcOffset("+05:30")
                                    .format("DD/MM/YYYY")}
                                </CustomTableCell>
                              ) : (
                                <CustomTableCell>N/A</CustomTableCell>
                              )}

                              <CustomTableCell>
                                {moment(refer.purchaseDate)
                                  .utcOffset("+05:30")
                                  .format("DD/MM/YYYY")}
                              </CustomTableCell>

                              <TableCell>
                                {refer.isDifferentTutorForDemo
                                  ? "True"
                                  : "False"}
                              </TableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TabPanel>
              )}

              {earningData?.menteesData?.menteesData?.length > 0 && (
                <TabPanel value="Mentees" sx={{ m: 0, p: 0 }}>
                  <Box sx={{ display: "flex", p: 2 }}>
                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        sx={{
                          color: "#647B76",
                          fontFamily: "Inter",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "20px",
                          mb: 1,
                        }}
                      >
                        Your Commission
                        <br />
                        <small>
                          Your avg mentees conversion is{" "}
                          {earningData?.menteesData?.avgMenteeConversion}%{" "}
                          <br /> so you will get{" "}
                          {earningData?.menteesData?.mentorConversion}%
                          commission
                        </small>
                        <Typography
                          style={{
                            color: "#4A6EE0",
                            fontFamily: "Inter",
                            fontSize: "13px",
                            fontStyle: "normal",
                            fontWeight: 500,
                            lineHeight: "20px",
                            cursor: "pointer",
                          }}
                          onClick={() =>
                            setOpenMentorshipCommissionDrawer(true)
                          }
                        >
                          View Criteria
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          color: "#082B28",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "24px",
                        }}
                      >
                        {currencyFormatter(
                          earningData?.mentorConversionAmount || 0
                        )}
                      </Box>
                    </Box>

                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ ml: 6, mr: 2 }}
                    />

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        sx={{
                          color: "#647B76",
                          fontFamily: "Inter",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "20px",
                          mb: 1,
                        }}
                      >
                        Your Commission %
                      </Box>
                      <Box
                        sx={{
                          color: "#082B28",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "24px",
                        }}
                      >
                        {earningData?.mentorConversionPercent || 0} %
                      </Box>
                    </Box>

                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ ml: 6, mr: 2 }}
                    />

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        sx={{
                          color: "#647B76",
                          fontFamily: "Inter",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "20px",
                          mb: 1,
                        }}
                      >
                        Avg Arpu
                      </Box>
                      <Box
                        sx={{
                          color: "#082B28",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "24px",
                        }}
                      >
                        {currencyFormatter(
                          earningData?.menteesData?.avgArpu || 0
                        )}
                      </Box>
                    </Box>

                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ ml: 6, mr: 2 }}
                    />

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        sx={{
                          color: "#647B76",
                          fontFamily: "Inter",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "20px",
                          mb: 1,
                        }}
                      >
                        Avg Attended Demo Sessions
                      </Box>
                      <Box
                        sx={{
                          color: "#082B28",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "24px",
                        }}
                      >
                        {earningData?.menteesData?.avgAttendedDemoSessions || 0}
                      </Box>
                    </Box>

                    <Divider
                      orientation="vertical"
                      flexItem
                      sx={{ ml: 6, mr: 2 }}
                    />

                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                      <Box
                        sx={{
                          color: "#647B76",
                          fontFamily: "Inter",
                          fontSize: "13px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "20px",
                          mb: 1,
                        }}
                      >
                        Referral Earnings
                      </Box>
                      <Box
                        sx={{
                          color: "#082B28",
                          fontFamily: "Inter",
                          fontSize: "16px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          lineHeight: "24px",
                        }}
                      >
                        {currencyFormatter(
                          earningData?.menteesData?.totalReferralEarnings || 0
                        )}
                      </Box>
                    </Box>

                    {/* <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ ml: 6, mr: 2 }}
                />

                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box
                    sx={{
                      color: "#647B76",
                      fontFamily: "Inter",
                      fontSize: "13px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "20px",
                      mb: 1,
                    }}
                  >
                    Min Conversion 
                  </Box>
                  <Box
                    sx={{
                      color: "#082B28",
                      fontFamily: "Inter",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 600,
                      lineHeight: "24px",
                    }}
                  >
                    {earningData?.menteesData?.minConversion || 0} %
                  </Box>
                </Box> */}
                  </Box>

                  <TableContainer>
                    <Table aria-label="a dense table">
                      <TableHead>
                        <TableRow style={{ backgroundColor: "#F2F5F4" }}>
                          <CustomTableCell>Mentee Name</CustomTableCell>
                          <CustomTableCell>Demo Conversion</CustomTableCell>
                          <CustomTableCell>ARPU</CustomTableCell>
                          <CustomTableCell> Referral Earning</CustomTableCell>
                          <CustomTableCell>Action</CustomTableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {earningData?.menteesData?.menteesData.map(
                          (mentee, i) => (
                            <TableRow key={i}>
                              <CustomTableCell>{mentee?.name}</CustomTableCell>
                              <CustomTableCell>
                                {mentee?.demoConversationPercentage} %
                              </CustomTableCell>
                              <CustomTableCell>
                                {currencyFormatter(mentee?.arpu || 0)}
                              </CustomTableCell>
                              <CustomTableCell>
                                {currencyFormatter(
                                  mentee?.referralEarning || 0
                                )}
                              </CustomTableCell>
                              <CustomTableCell>
                                <a
                                  href="#"
                                  onClick={() =>
                                    history.push(`/mentee/${mentee._id}`)
                                  }
                                >
                                  Review Session
                                </a>
                              </CustomTableCell>
                            </TableRow>
                          )
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </TabPanel>
              )}
            </TabContext>
          </>
        )}
      </Box>
      <NetEarningBreakDown
        openDrawer={openDrawer}
        setOpenDrawer={setOpenDrawer}
        totalPenalty={earningData?.totalPenalty}
        tds={tds}
        bonus={bonus}
        totalHours={hourMinuteConverter(earningData?.totalHours)}
        totalSessionEarning={Math.round(earningData?.totalSessionEarning)}
        referralEarning={Math.round(referralEarning)}
        noOfLateJoin={Number(earningData?.latePenalty)}
        lateJoinPenalty={Number(earningData?.lateJoinPenalty)}
        noOfMissedSession={Number(earningData?.sessionMissingPenalty)}
        missedSessionPenalty={Number(earningData?.missingSessionPenalty)}
        cancelledSessions={earningData?.reassignPenalty}
        startDate={startDate}
        endDate={endDate}
        fixedPay={earningData?.fixedPay}
        isSalesPerson={teacher?.isSalesPerson}
        arpu={earningData?.arpu}
        avgOfferDiscount={earningData?.avgOfferDiscount}
        demoConversationPercentage={earningData?.demoConversationPercentage}
        revenueGenerated={earningData?.revenueGenerated}
        isFluencyCounsellor={teacher?.isFluencyCounsellor}
        allowAutoSwap={teacher?.allowAutoSwap}
        commissionCriteria={commissionCriteria}
        cancelDemoSession={earningData?.cancelDemoSessionsWithinOneHour}
        totalSessions={totalSession}
        mentorshipConversionAmount={earningData?.mentorConversionAmount}
        mentorConversionPercent={earningData?.mentorConversionPercent}
        bookedDemosCount={earningData?.bookedDemosCount}
        demoAttended={earningData?.demoAttended}
      />
      <BonosCriteria
        openBonosDrawer={openBonosDrawer}
        setOpenBonosDrawer={setOpenBonosDrawer}
        totalHours={hourMinuteConverter(earningData?.totalHours)}
        bonus={bonus}
      />
      <MentorshipCommissionCriteria
        openMentorshipCommissionDrawer={openMentorshipCommissionDrawer}
        setOpenMentorshipCommissionDrawer={setOpenMentorshipCommissionDrawer}
        avgMenteesConversion={earningData?.menteesData?.avgMenteeConversion}
        commissionAmount={earningData?.mentorConversionAmount}
        mentorConversion={earningData?.menteesData?.mentorConversion}
        avgArpu={earningData?.menteesData?.avgArpu}
        avgAttendedDemoSessions={
          earningData?.menteesData?.avgAttendedDemoSessions
        }
      />
      <ReferralCriteria
        openReferralDrawer={openReferralDrawer}
        setOpenReferralDrawer={setOpenReferralDrawer}
        teacherDemoConversionPercentage={
          earningData?.demoConversationPercentage
        }
        convProjectedEarnings={convProjectedEarnings}
        convReferralEarnings={Math.round(convReferralEarnings)}
        isCommissionCriteriaPassed={isCommissionCriteriaPassed}
        isSalesPerson={teacher?.isSalesPerson}
        arpu={earningData?.arpu}
        avgOfferDiscount={earningData?.avgOfferDiscount}
        isFluencyCounsellor={teacher?.isFluencyCounsellor}
        demoAttended={earningData?.demoAttended}
        commissionCriteria={commissionCriteria}
        bookedDemosCount={earningData?.bookedDemosCount}
      />
    </>
  );
};

export default EarningsV2;
