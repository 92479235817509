import axios from "axios";
import {PROD_API,DEV_API,LOCAL_HOST,GLOBAL_API} from './ApiConst'

const token = JSON.parse(localStorage.getItem("TOKEN"))

if(token){
    axios.defaults.headers.common["x-access-token"] = token;
}

axios.defaults.headers.common["platform"] = "teacher web";
axios.defaults.headers.common["app_events"] = "true";
// eslint-disable-next-line import/no-anonymous-default-export
export default {
    Session(url = `${process.env.REACT_APP_BASE_URL}/`) {
        return {
            teacherPandingRequest: (data) => axios.post(url+"api/session/teacherPendingRequests",data),
            acceptORdecline: (data) => axios.put(url+"api/session/acceptorreject",data),
            teacherUpcomingSession: (data) => axios.post(url+"api/session/teacherupcomingsessionnew",data),
            teacherPastSession: (data) => axios.post(url+"api/session/teacherpastsessionnew",data),
            teacherEarning: (data) => axios.post(url+"api/teacher/getTeacherEarnings",data),
            teacherFeedback: (data) => axios.put(url+"api/session/teacherfeedback",data),
            teacherWeeklyTiming: (data) => axios.post(url+"api/schedule/setTeacherTimingNew",data),
            teacherWeeklyKidsTiming: (data) => axios.put(url+"api/schedule/addKidsSlot",data),
            getTeacherTiming: (data) => axios.post(url+"api/schedule/getTeacherTimingnew",data),
            updateTeacherTiming: (data) => axios.put(url+"api/schedule/updateteacheravalabletimenew",data),
            getTeacherBreaks: (data) => axios.get(url+`api/schedule/getBreaks?teacherId=${data?.teacherId}`),
            addOrUpdateBreaks: (data) => axios.post(url+"api/schedule/addOrUpdateBreaks",data),
            TeacherNotes: (data) => axios.post(url+"api/teacher/findteacherNoteByCount",data),
            BlockLearner: (data) => axios.put(url+"api/teacher/blockLearner",data),
            UnBlockLearner: (data) => axios.put(url+"api/teacher/unBlockLearner",data),
            GetBlockLearner: (data) => axios.get(url+"api/teacher/getBlockedLearners"),
            MarkAttended: (data) => axios.post(url+"api/session/isSessionAttended",data),
            MarkCertification: (data) => axios.post(url+"api/teacher/markCertificationByTeacher",data),
            TeacherFeedback: (data) => axios.post(url+"api/rating/v1/addTeacherFeedback",data),
            SyncTeacer: (data) => axios.post(url+"api/schedule/isTeacherSync",data),
            sessionAttendance: (data) => axios.post(url+"api/session/updateSpeakerJoiningTime",data),
            getHomeworkSolution:(data) => axios.post(url+"api/learner/getHomeworkSolution",data),
            getTimingSubjectWise:(subject) => axios.get(url+`api/schedule/getTimingSubjectWise?subject=${subject}`),
            addSessionScore:(score) => axios.post(url+`api/rating/add-kids-session-score`,score),
            cancelAndReassign:(data) => axios.post(url+`api/session/cancelReassign`,data),
            teacherAttendance: (data,params) => axios.post(url +`api/session/individual-teacher-attendance?startDate=${params.startDate}&endDate=${params.endDate}`,data),
            getFeedbacks: ({staDate,endDate}) => axios.get(url +`api/teacher/getSessionFeedback?staDate=${staDate}&endDate=${endDate}`),
            getFeedbacksByRatingType: ({staDate,endDate, ratingType}) => axios.get(url +`api/teacher/getSessionFeedback?staDate=${staDate}&endDate=${endDate}&ratingType=${ratingType}`),
            hotLead:(data,subject) => axios.post(url+`${subject === "IELTS"?'ielts-hot-lead':subject === "English"?"english-hot-lead":'hot-lead'}`,data),
            getPayouts:(teacherId) => axios.get(url+`api/payouts/getPayouts?teacherId=${teacherId}`),
            withdrawAmount:(data) => axios.post(url+`api/payouts/withdrawAmount`,data),
            getFeedBackForNextTutor:({teacherId, learnerId, subject}) => axios.get(url+`api/rating/getFeedBackForNextTutor?learnerId=${learnerId}&teacherId=${teacherId}&subject=${subject}`),
            getAssignedLearner:({teacherId}) => axios.get(url+`api/teacher/getAllAssignedLearners?teacherId=${teacherId}`),
            raiseDispute: (data) => axios.put(url + "api/session/raiseDispute", data),
            // Create a session
            getAvailableHost:(data) => axios.post(url+`api/session/blockHost`,data),
            createZoomMeeting:(data) => axios.post(url+`api/session/createmeeting`,data),
            saveSessionInDB:(data) => axios.post(url+`api/session/createnew`,data),
            createSession:(data) => axios.post(url+`api/session/v1/createnew`,data),
            tags:() => axios.get(url+`api/tags`),
            getTeacherSlots: (data) => axios.post(url+`api/schedule/getAvailableTeachersAdmin`, data),
            raiseConcernByTeacher : (data) => axios.post(url + `api/teacher/raiseConcernByTeacher`, data),
            teacherConcern: () => axios.get(url + "api/teacher/getRaiseConcernById"),
            getTeacherConvStats: (data) => axios.get(url + `api/teacher/getTeacherConversationStats?startDate=${data.startDate}&endDate=${data.endDate}&timezone=${data.timezone}`, data),
            getTeacherLeaderboard: (data) => axios.get(url + `api/teacher/teacherLeaderboard?count=5`),
            makeCall:(data) => axios.post(url + `api/teacher/make-call`, data),
            reportTechIssue:(data) => axios.post(url + `api/rating/reportTechIssue`, data),
            getAllDemoRequests: ({page, limit}) => axios.get(url + `api/session/liveDemo?page=${page}&limit=${limit}`),
            declineDemoRequest: (data) => axios.put(url + `api/session/declineDemoRequest`, data),
            getAllCoupon: (data) => axios.post(url + `api/offers/v1/activeCoupons`, data),
            getAllUpdates: (data) => axios.get(url + `api/teacher/getAllReleaseNotes`),
            getAllAIFeedback: (userId) => axios.get(url + `api/learner/English/${userId}?fluencyTest="true"`),
            salesTeamlearnerDetails: (data) => axios.post(url + `api/teacher/fetchSalesTeamLearnerDetails`, data),
            getDemoRequests: () => axios.get(url + `api/session/demoOpportunities`),
            getPseudoDemoSlots: () => axios.get(url + `api/session/getPsuedoSlots?page=1&limit=1000`),
            acceptDemoRequests: (data) => axios.put(url + `api/session/acceptDemoOpportunity`,data)
        }
    }
}