import React, { useState, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { images } from "../assets";
import mobileImages from "../assets/sidelogogreen.png";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import "./layout.scss";
import { useDispatch, useSelector } from "react-redux";
import { GetAllUnreadMessage } from "../redux/actions/ChatAction";
import useBookingRequestToast from "../component/InstantBookingNotification/useBookingRequestToast";
import toast from "react-hot-toast";
import NotificationRequestPopup from "../component/NotificationRequestPopup";
import {
  GetAllDemoRequests,
  InitializeInstantBookingSocket,
  MarkCertificate,
  UpdateInstantBookingRequestsCount,
} from "../redux/actions/SessionAction";
// import { setToken, deleteFCMToken } from "../helper/firebaseHelper";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);
const BaseLayout = ({ children }) => {
  const PastSessions = useSelector((state) => state.TeacherReducer.pastSession);
  const location = useLocation();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [notificationReqPopup, setNotificationReqPopup] = useState(false);
  const [peerLearningEnabled, setPeerLearningEnabled] = useState(false);

  const unreadMsg = useSelector((state) => state.TeacherReducer.unreadMessages);
  const teacher = useSelector((state) => state.TeacherReducer.teacher);
  const instantBookingSocket = useSelector(
    (state) => state.TeacherReducer.instantBookingSocket
  );
  const instantBookingRequestsCount = useSelector(
    (state) => state.TeacherReducer.instantBookingRequestsCount
  );
  const history = useHistory();
  const dispatch = useDispatch();
  const { openBookingRequestToast, dismissToastFromBottom } =
    useBookingRequestToast();
  const socketReconnectAttempt = useRef(0);


  const [sessionEndedModalOpen, setSessionEndedModalOpen] = useState(false);
  const [selectedSessionId, setSelectedSessionId] = useState(null);
  useEffect(() => {
    if (PastSessions && PastSessions.length > 0) {
      PastSessions.forEach((sessionData) => {
        if (
          sessionData?.topic?.type &&
          sessionData?.topic?.isCertificationPassed === undefined
        ) {
          setSessionEndedModalOpen(true);
          setSelectedSessionId(sessionData._id);
        }
      });
    }
  }, [PastSessions, history.location.pathname]);

  const markPassOrFail = (result) => {
    if (selectedSessionId) {
      const data = {
        sessionId: selectedSessionId,
        isCertificationPassed: result,
      };
      const onSuccess = (msg) => {
        console.log(msg);
        toast.success("Your selection has been marked successfully!");
        // Handle success, if needed
        setSessionEndedModalOpen(false);
      };
      const onError = (msg) => {
        console.log("error", msg);
        // Handle error, if needed
        setSessionEndedModalOpen(false);
        toast.error("Marking failed. Please try again.");
      };

      dispatch(MarkCertificate(data, onSuccess, onError));
    }
  };

  function toggleSidebar() {
    var sidebar = document.getElementById("sidebar");
    if (!sidebarOpen) {
      sidebar.classList.add("sidebar_responsive");
      setSidebarOpen(true);
    }
  }

  function closeSidebar() {
    var sidebar = document.getElementById("sidebar");
    if (sidebarOpen) {
      sidebar.classList.remove("sidebar_responsive");
      setSidebarOpen(false);
    }
  }

  const getDemoRequestsCount = () => {
    let payload = {
      page: 1,
      limit: 0,
    };
    const onSuccess = (data) => { };
    const onError = (err) => {
      toast.error(err);
    };
    dispatch(GetAllDemoRequests(payload, onSuccess, onError));
  };

  useEffect(() => {
    if (teacher) {
      GetAllUnreadMessage(teacher?._id, dispatch);
      const peerLearningSubject = teacher?.subjects?.filter(
        (data) => data?.peerLearning === true
      );
      if (peerLearningSubject?.length > 0) {
        setPeerLearningEnabled(true);
      }
    }
  }, [teacher]);

  useEffect(() => {
    if (teacher?._id) {
      getDemoRequestsCount();
      const expiredRequestListener = (data) => {
        dismissToastFromBottom(data.learnerId);
        dispatch(UpdateInstantBookingRequestsCount());
      };
      const incomingRequestListener = (data) => {
        openBookingRequestToast({
          id: data.learner.id,
          name: data.learner.name,
        });
        dispatch(UpdateInstantBookingRequestsCount());
      };

      const onInit = (socket) => {
        socket.on("instant_booking_session", incomingRequestListener);
        socket.on("update_live_demo_screen", expiredRequestListener);
        socket.on("disconnect", () => {
          socketReconnectAttempt.current++;
          if (socketReconnectAttempt.current < 6) {
            setTimeout(() => {
              // console.log("socket: reattempt", socketReconnectAttempt.current);
              socket.connect();
            }, 1000);
          }
        });
      };

      const onConnect = () => {
        socketReconnectAttempt.current = 0;
      };

      dispatch(InitializeInstantBookingSocket(teacher._id, onInit, onConnect));
      return () => {
        if (instantBookingSocket) instantBookingSocket.close();
      };
    }
  }, [teacher?._id]);

  useEffect(() => {
    if (!navigator.onLine) {
      toast.error(
        "You are currently offline. Please check your internet connection."
      );
    }
    function handleOnline() {
      if (instantBookingSocket && location.pathname !== "/signin")
        instantBookingSocket.connect();
      toast.success("You are back online.");
    }
    function handleOffline() {
      toast.error(
        "You are currently offline. Please check your internet connection."
      );
    }
    if (instantBookingSocket) {
      window.addEventListener("online", handleOnline);
      window.addEventListener("offline", handleOffline);
    }

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, [instantBookingSocket]);

  // useEffect(() => {
  //   if (Notification.permission === "default")
  //     setTimeout(() => {
  //       setNotificationReqPopup(true);
  //     }, 3000)
  //   else if (Notification.permission === "granted") setToken();
  // }, [])

  const logout = () => {
    if (instantBookingSocket) {
      socketReconnectAttempt.current = 5;
      instantBookingSocket.close();
    }
    // deleteFCMToken(() => {
    localStorage.removeItem("TEACHER");
    history.push("/signin");
    if (window.fcWidget !== undefined && window.fcWidget.isInitialized()) {
      window.fcWidget.destroy();
      console.log("user else freshchat widget destroyed");
    }
    // })
  };

  return (
    <div id="body">
      <div className="container">
        {location.pathname === "/session-document" ||
          location.pathname === "/earnings" ? (
          ""
        ) : (
          <nav className="navbar">
            <div className="logo">
              <img className="logo" src={mobileImages} alt="clapingo logo" />
            </div>
            {sidebarOpen ? (
              <i
                onClick={() => closeSidebar()}
                className="fa fa-times"
                id="sidebarIcon"
                aria-hidden="true"
              ></i>
            ) : (
              <div className="nav_icon" onClick={toggleSidebar}>
                <i className="fa fa-bars" aria-hidden="true"></i>
              </div>
            )}
          </nav>
        )}

        <main>
          <div onClick={() => closeSidebar()}>{children}</div>
        </main>

        <div id="sidebar">
          <div className="sidebar__title">
            <div className="sidebar__img">
              <img src={images.logo} alt="logo" />
              <h1>Clapingo Speaker</h1>
            </div>
          </div>

          <div className="sidebar__menu">
            <div
              className={`sidebar__link ${location.pathname == "/" ? "active_menu_link" : ""
                }`}
            >
              <i className="fa fa-home"></i>
              <Link to="/">Dashboard</Link>
            </div>
            <div
              className={`sidebar__link ${location.pathname == "/upcomingsession"
                  ? "active_menu_link"
                  : ""
                }`}
            >
              <i className="fa fa-laptop" aria-hidden="true"></i>
              <Link to="/upcomingsession">Upcoming Session</Link>
            </div>
            {/* Conditionally render the "Demo Requests" link */}
            {(teacher?.isSalesPerson || teacher?.isFluencyCounsellor || teacher?.allowAutoSwap) && (
              <div
                className={`sidebar__link ${location.pathname == "/demorequests" ? "active_menu_link" : ""
                  }`}
              >
                <i className="fa fa-laptop" aria-hidden="true"></i>
                <Link to="/demorequests">Demo Requests</Link>
              </div>
            )}
            <div
              className={`sidebar__link ${location.pathname == "/pastsession" ? "active_menu_link" : ""
                }`}
            >
              <i className="fa fa-laptop-medical"></i>
              <Link to="/pastsession">Past Session</Link>
            </div>
            <div
              className={`sidebar__link ${location.pathname == "/workinghour" ? "active_menu_link" : ""
                }`}
            >
              <i className="fa fa-calendar-alt"></i>
              <Link to="/workinghour">Your Working Hours</Link>
            </div>
            <div
              className={`sidebar__link ${location.pathname == "/schedule" ? "active_menu_link" : ""
                }`}
            >
              <i className="fa-sharp fa-solid fa-clock"></i>
              <Link to="/schedule">Your Bookings</Link>
            </div>
            {/*<div className={`sidebar__link ${location.pathname == '/instantbooking' ? 'active_menu_link' : ""}`}>
              <div className="live-ping-container">
                <i className="fa-solid fa-circle live-ping animate-ping"></i>
                <i className="fa-solid fa-circle live-ping"></i>
              </div>
              <Link to="/instantbooking">Instant Booking</Link>
              <div className="msg-count">{instantBookingRequestsCount}</div>
            </div>*/}
            {!peerLearningEnabled && (
              <>
                <div
                  className={`sidebar__link ${location.pathname == "/attendance" ? "active_menu_link" : ""
                    }`}
                >
                  <i className="fas fa-clipboard-check"></i>
                  <Link to="/attendance">Session Attendance</Link>
                </div>
                <div
                  className={`sidebar__link ${location.pathname == "/feedback" ? "active_menu_link" : ""
                    }`}
                >
                  <i className="fas fa-comment"></i>
                  <Link to="/feedback">Session Feedback</Link>
                </div>
                <div
                  className={`sidebar__link ${location.pathname == "/activeCoupons"
                      ? "active_menu_link"
                      : ""
                    }`}
                >
                  <i className="fa fa-bell"></i>
                  <Link to="/activeCoupons">Notifications</Link>
                  {<div className="tag">New</div>}
                </div>
                <div
                  className={`sidebar__link ${location.pathname == "/earnings" ? "active_menu_link" : ""
                    }`}
                >
                  <i className="fas fa-coins"></i>
                  <Link to="/earnings">Earnings</Link>
                </div>
                <div
                  className={`sidebar__link ${location.pathname == "/blocklearner"
                      ? "active_menu_link"
                      : ""
                    }`}
                >
                  <i className="fas fa-ban"></i>
                  <Link to="/blocklearner">Blocked Learners</Link>
                </div>
                {!teacher?.isSalesPerson ? (
                  <div
                    className={`sidebar__link  ${location.pathname == "/chatpage" ? "active_menu_link" : ""
                      }`}
                  >
                    <i className="fas fa-comment-alt"></i>
                    <Link to="/chatpage">Messages </Link>
                    {unreadMsg > 0 ? (
                      <div className="msg-count">{unreadMsg}</div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : null}
                {!teacher?.isSalesPerson && !teacher?.isFluencyCounsellor ? (
                  <div
                    className={`sidebar__link ${location.pathname === "/book-session"
                        ? "active_menu_link"
                        : ""
                      }`}
                  >
                    <i className="fas fa-clipboard-check"></i>
                    <Link to="/book-session">Assigned Learners</Link>
                  </div>
                ) : null}
                {teacher?.isSalesPerson || teacher?.isFluencyCounsellor ? (
                  <div
                    className={`sidebar__link ${location.pathname === "/payment-link"
                        ? "active_menu_link"
                        : ""
                      }`}
                  >
                    <i className="fas fa-money-bill"></i>
                    <Link to="/payment-link">Generate Payment Link</Link>
                  </div>
                ) : null}
              </>
            )}
            {/* <div className={`sidebar__link ${location.pathname == '/payouts' ? 'active_menu_link' : ""}`}>
              <i class="fas fa-money-check"></i>
              <Link to="/payouts">Payouts</Link>
            </div> */}

            <div
              className={`sidebar__link ${location.pathname == "/profile" ? "active_menu_link" : ""
                }`}
            >
              <i className="fa fa-user-circle"></i>
              <Link to="/profile">Profile</Link>
            </div>

            <div className="sidebar__logout" onClick={logout}>
              <i className="fa fa-power-off"></i>
              <a href="#">Log out</a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <Dialog
            onClose={() => setSessionEndedModalOpen(false)}
            maxWidth="lg" // Set to "lg" for large size
            aria-labelledby="customized-dialog-title"
            open={sessionEndedModalOpen}
          >
            <DialogContent
              dividers
              style={{ width: "600px", padding: "15px", textAlign: "center" }}
            >
              <h2 style={{ marginBottom: "40px" }}>
                Based on the minimum evaluation criteria for the assesment, Your
                feedback for the learner is?
              </h2>
              {/* Buttons for marking as Pass or Fail */}
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  marginBottom: "40px",
                }}
              >
                <Button
                  onClick={() => markPassOrFail(true)}
                  style={{
                    backgroundColor: "#4CAF50",
                    color: "white",
                    marginRight: "5px",
                  }}
                  autoFocus
                >
                  Certify(Pass)
                </Button>
                <Button
                  onClick={() => markPassOrFail(false)}
                  style={{ backgroundColor: "#FF0000", color: "white" }}
                >
                  Reattempt(Fail)
                </Button>
              </div>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={() => setSessionEndedModalOpen(false)}
                color="primary"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default BaseLayout;
