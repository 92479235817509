import React, { useEffect, useState } from 'react'
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import { useDispatch, useSelector } from 'react-redux';
import { currentTimeUtc, dateFormater, formatAMPM, sessionBookingDateUTCObj, utcToLocal, utcToLocalDateConverter } from '../helper/sessionHelper';
import { useSnackbar } from 'react-simple-snackbar';
import { Blocklearner, CancelAndReassign, GetAllAIFeedback, GetFeedBackForNextTutor, HotLead, MakeCall, MarkCertificate, SessionAttendance, TeacherNotesBySessionCount, UnBlocklearner, ZoomMeetingRecording } from '../redux/actions/SessionAction';
import { useHistory } from 'react-router-dom';
import FeedbackModal from './FeedbackModal';
import FeedbackModalV2 from './FeedbackModalV2';
import CurriculumFeedbackModal from './CurriculumFeedbackModal';
import SessionScore from './SessionScore';
import moment from 'moment';
import MarkTechFailureModal from './MarkTechFailureModal';
import { demoAttendedEvent } from '../helper/amplitudeHelper';
import { DialogTitle , CircularProgress} from '@material-ui/core';

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const LearnerInfoDialog = ({ sessionData, type }) => {
  const [open, setOpen] = useState(false);
  const [feedback, setFeedback] = useState("");
  const [openSnackbar, closeSnackbar] = useSnackbar();

  const teacher = JSON.parse(localStorage.getItem("TEACHER"));

  const handleClickOpen = () => {
    // console.log(JSON.parse(localStorage.getItem("TEACHER"))._id);
    const data = {
      teacherId: JSON.parse(localStorage.getItem("TEACHER"))._id,
      learnerId: sessionData.learner?._id,
      subject: sessionData?.subjects
    }
    const onSuccess = (res) => {
      if (res.feedbackData) {
        setFeedback(res.feedbackData);
      } else {
        setFeedback(res.message);
      }
    };

    const onError = (msg) => {
      if(type==="past") openSnackbar(msg);
    };

    GetFeedBackForNextTutor(data, onSuccess, onError);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


console.log("HMS", process.env.REACT_APP_HMS_MEET_LINK)
  return (
    <div >
      <p onClick={handleClickOpen}><i className="fas fa-info-circle"></i> User details</p>
      <Dialog onClose={handleClose} maxWidth="md" aria-labelledby="customized-dialog-title" open={open}>
        <DialogContent dividers style={{ width: "400px", padding: "15px" }}>
          <p><strong>Name :</strong>{sessionData.learner ? sessionData.learner.name ? sessionData.learner.name : "Not available" : "Not available"}</p>
          {
            teacher?.isDemo &&
            <p><strong>Number :</strong>{sessionData.learner ? sessionData.learner.phoneNumber ?
              <a href={`tel:${sessionData.learner.phoneNumber}`}>{sessionData.learner.phoneNumber}</a>
               : "Not available" : "Not available"}</p>
          }

          {sessionData.learner?.age ? <p><strong>Age :</strong> {sessionData.learner?.age}</p> : ""}
          {sessionData.learner?.grade ? <p><strong>Grade :</strong> {sessionData.learner?.grade}</p> : ""}
          {sessionData.learner?.currentLevel ? <p><strong>Level :</strong> {sessionData.learner?.currentLevel}</p> : ""}
          <p><strong>Profession :</strong>{sessionData.learner ? sessionData.learner.profession ? sessionData.learner.profession : "Not available" : "Not available"}</p>
          <p><strong>City :</strong>{sessionData.learner ? sessionData.learner.city ? sessionData.learner.city : "Not available" : "Not available"}</p>
          <p><strong>Total session with learner:</strong>{sessionData?.pastSessionCount ? sessionData?.pastSessionCount : "Not available"}</p>
          {
            sessionData?.subjects === "IELTS" ?
              feedback ?
                <ol style={{ margin: '15px' }}>
                  {

                    feedback?.map((data, i) => {
                      if (data?.question !== "Chances of them enrolling") {
                        return <li key={i}><strong>{data?.question}</strong>:{data?.answer}</li>
                      }
                    })

                  }
                </ol>
                : ""
              :
              <p><strong>Feedback :</strong>{feedback ? feedback : "Not available"}</p>
          }
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const FeedBackModal = ({ sessionData }) => {
  const [open, setOpen] = useState(false);
  const [feedbackData, setFeedBackData] = useState(null); // Initialize as null to handle loading state
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const userId = sessionData?.learner?._id;

  useEffect(() => {
    if(userId){
    const onSuccess = (data) => {
      setFeedBackData(data);
    };
    const onError = (msg) => {
      openSnackbar(msg);
    };
    GetAllAIFeedback(userId, onSuccess, onError);
  }
  }, [userId]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderCircularProgress = (score) => (
    <div style={{ position: 'relative', width: 50, height: 50, marginBottom:"0.8rem" }}>
      <CircularProgress
        variant="static"
        value={score}
        style={{ position: 'absolute', top: 0, left: 0 , color: score < 50 ? 'red' : 'green'}}
        size={50}
        thickness={5}
      />
      <div style={{ position: 'absolute', top: '50%', left: '50%' ,transform: 'translate(-50%, -50%)', marginRight:"1rem",color: score < 50 ? 'red' : 'green' }}>
        {`${score}%`}
      </div>
    </div>
  );

  return (
    <div>
      <button style={{ background: "#24A791", color: "white", width: "100%", cursor: "pointer" }} onClick={handleClickOpen}> AI Feedback</button>
      <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
        <DialogTitle id="alert-dialog-title">
          <div style={{ fontWeight: "bold", textAlign: "center", fontSize: "1.5rem" }}>Scores based on the test</div>
        </DialogTitle>
        <DialogContent dividers style={{ padding: "1rem", maxHeight: "80vh", overflowY: "auto" }}>
          {/* Check if feedbackData exists to prevent errors */}
          {feedbackData && feedbackData.learner.testScores.map((testScore, index) => (
            <div key={index} style={{ marginBottom: "4rem" }}>
              <div style={{ fontSize: "1.5rem", fontWeight: "bold" }}>{`Test-${index + 1} Score`}</div>
              <div style={{ display: "flex", flexDirection: "row", marginTop: "2rem", gap: "8rem" }}>
                <div>
                  <div>{renderCircularProgress(testScore.data.scores.accuracy_score)}<p>Accuracy</p></div>
                  <div>{renderCircularProgress(testScore.data.scores.grammar_score)}<p>Grammar</p></div>
                </div>
                <div>
                <div>{renderCircularProgress(testScore.data.scores.pronunciation_score)}<p>Pronunciation</p></div>
                <div>{renderCircularProgress(testScore.data.scores.vocabulary_score)}<p>Vocabulary</p></div>
                </div>
              </div>
              <div style={{ display: "flex", flexDirection: "row", gap: "1rem", marginTop: "2rem" }}>
                <div style={{ width: "46%", background: "#D3D3D3", padding: "1rem", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",borderRadius: "10px"}}>
                  <p style={{ marginBottom: "1rem", fontSize: "1.2rem", fontWeight: "bold" }}>Recognized Text:</p>
                  <div>{testScore.data.recognized_text}</div>
                </div>
                <div style={{ width: "46%", background: "#D3D3D3", padding: "1rem", boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",borderRadius: "10px"}}>
                  <p style={{ marginBottom: "1rem", fontSize: "1.2rem", fontWeight: "bold" }}>Corrected Text:</p>
                  <div>{testScore.data.corrected_paragaph}</div>
                </div>
              </div>
            </div>
          ))}
        </DialogContent>
        <DialogActions style={{ justifyContent: 'flex-start', paddingLeft: '1rem' }}>
          <Button onClick={handleClose} style={{ background: "#24A791", color: "#FFFFFF", cursor: "pointer", fontWeight: "bold" }}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}



const CancelSessionDialog = ({ width, cancelBtnLoader, setCancelBtnLoader, openSnackbar, dispatch, sessionData }) => {
  const [open, setOpen] = useState(false);
  const teacher = JSON.parse(localStorage.getItem("TEACHER"));

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const cancelSession = (data) => {
    setCancelBtnLoader(true)

    const onSuccess = () => {
      setCancelBtnLoader(false)
      openSnackbar('Session successfully canceled');
    }
    const onError = (errMsg) => {
      setCancelBtnLoader(false)
      openSnackbar(errMsg);
      setOpen(false);
    }

    dispatch(CancelAndReassign({ sessionId: data._id }, onSuccess, onError))
  }

  return (
    <>
      <button className='cancel' disabled={sessionData?.instantBookingDetails?.id || cancelBtnLoader} style={{ width: width }} onClick={handleClickOpen}>
        {cancelBtnLoader ? 'Canceling..' : 'Cancel session'}
      </button>
      <Dialog onClose={handleClose} maxWidth="md" aria-labelledby="customized-dialog-title" open={open}>
        <DialogContent dividers style={{ width: "400px" }}>
          <p>{teacher.penaltyAmount} rs will be deducted from your total earnings amount as a cancellation fine if you cancel the session.</p>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Close
          </Button>
          <Button autoFocus onClick={() => cancelSession(sessionData)} color="primary" disabled={cancelBtnLoader}>
            {cancelBtnLoader ? 'Canceling..' : 'Ok'}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export const UpcomingSessionCard = ({ sessionData, highlight }) => {

  const dispatch = useDispatch()
  const [joiningLoader, setJoiningLoader] = useState(false)
  const [notesLoader, setNotesLoader] = useState(false)
  const [cancelBtnLoader, setCancelBtnLoader] = useState(false)
  const [hotLeadLoader, setHotLeadLoader] = useState(false)
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [peerLearningEnabled, setPeerLearningEnabled] = useState(false);
  const [feedbackData, setFeedBackData] = useState("");
  const userId= sessionData?.learner?._id;

  const UpcommingSession = useSelector(
    (state) => state.TeacherReducer.upcomingData
  );
  console.log("SESSION DATA", sessionData)
  useEffect(() => {
    if(userId){
      const onSuccess = (data) => {
        setFeedBackData(data?.learner?.testScores)
        // console.log("fee", feedbackData)
      };
      const onError = (msg) => {
        openSnackbar(msg);
      };
      GetAllAIFeedback(userId, onSuccess, onError);
    }
  }, [userId])

  const history = useHistory();
  const imgUrl = "https://s3.ap-south-1.amazonaws.com/cdn.clapingo.com/clapingoWeb";

  const teacher = JSON.parse(localStorage.getItem("TEACHER"));
  // console.log("session data", sessionData)
  useEffect(() => {
    if(teacher){
    const peerLearningSubject = teacher?.subjects?.filter(data => data?.peerLearning === true)
    if(peerLearningSubject?.length > 0){
      setPeerLearningEnabled(true)
    }
  }
  }, [teacher])
  

  const joinMeeting = (data) => {
    if (data.isTimeUtc) {
      var joiningDate = utcToLocal(data.sessionDate, data.startTime);
      var joiningDate2 = utcToLocal(data.sessionDate, data.endTime);
      var currentTime = new Date();
      const isJoiningTime = joiningDate <= currentTime && currentTime <= joiningDate2;

      if (joiningDate > currentTime) {
        openSnackbar("Your session not started yet");
      } else if (joiningDate2 < currentTime) {
        openSnackbar("You cant join. Meeting is over");
      } else {
        setJoiningLoader(data._id)
        const baseURL = window.location.origin; // Get the base URL without the path
        const newURL = `${baseURL}/pastsession`;
        let url = "";
        if(data?.type==="Demo" && data?.sdkType==="HMS"){

          url=`${process.env.REACT_APP_HMS_MEET_LINK}/preview/${data?.roomCode}?learner=${data?.teacher?._id}&type=teacher&name=${data?.teacher?.name}&endTime=${data?.endTime}&leaveUrl=${newURL}`

          setJoiningLoader(false)
          Object.assign(document.createElement('a'), {
            target: '_blank',
            href: url,
          }).click();
        } else {
          url= `https://zoom.clapingo.com/?meetingNumber=${data.meetingNo}&passWord=${data.meetingPassword}&userName=${data.teacher?.name}&leaveurl=${newURL}&endTime=${joiningDate2}&type=Teacher`;
        }
        

        const onSuccess = () => {
          setJoiningLoader(false)
          Object.assign(document.createElement('a'), {
            target: '_blank',
            href: url,
          }).click();

          const eventProps = {
            demo_video_url: url, 
            tutor_name: data?.teacher?.name || "N/A", 
            email: teacher?.email, 
            phone_number: teacher?.phoneNumber, 
            tutor_id: data?.teacher?._id, 
            meeting_no: data?.meetingNo, 
            error: "N/A", 
            session_date: sessionBookingDateUTCObj(data?.sessionDate, data?.startTime).format("DD-MM-YYYY"),
            session_duration: parseInt(data?.sessionDuration?.split("min")[0]), 
            session_time: data?.startTime, 
            session_type: data?.type, 
            subject: data?.subjects, 
            learner_name: data?.learner?.name
          };
          // if(data?.type==="Demo") demoAttendedEvent(eventProps);
        }

        const onError = (error) => {
          setJoiningLoader(false)
          openSnackbar(error);
          const eventProps = {
            demo_video_url: url, 
            tutor_name: data?.teacher?.name || "N/A", 
            email: teacher?.email, 
            phone_number: teacher?.phoneNumber, 
            tutor_id: data?.teacher?._id, 
            meeting_no: data?.meetingNo, 
            error, 
            session_date: sessionBookingDateUTCObj(data?.sessionDate, data?.startTime).format("DD-MM-YYYY"),
            session_duration: parseInt(data?.sessionDuration?.split("min")[0]), 
            session_time: data?.startTime, 
            session_type: data?.type, 
            subject: data?.subjects, 
            learner_name: data?.learner?.name
          };
          // if(data?.type==="Demo") demoAttendedEvent(eventProps);
        }

        if (data?.sdkType !== "HMS") {
          dispatch(SessionAttendance({ sessionID: data._id, joiningTime: currentTimeUtc() }, onSuccess, onError));
        }

      }
    }
  };

  const joinViaLink = (data) => {
    if (data.isTimeUtc) {
      var joiningDate = utcToLocal(data.sessionDate, data.startTime);
      var joiningDate2 = utcToLocal(data.sessionDate, data.endTime);
      var currentTime = new Date();

      if (joiningDate > currentTime) {
        openSnackbar("Your session not started yet");
      } else if (joiningDate2 < currentTime) {
        openSnackbar("You cant join. Meeting is over");
      } else {
        setJoiningLoader(data._id)
        const onSuccess = () => {
          setJoiningLoader(false)
          const url = data.joinMeetingUrl;
          Object.assign(document.createElement('a'), {
            target: '_blank',
            href: url,
          }).click();
        }

        const onError = (error) => {
          setJoiningLoader(false)
          openSnackbar(error);
        }

        dispatch(SessionAttendance({ sessionID: data._id, joiningTime: currentTimeUtc() }, onSuccess, onError))

      }
    }
  }

  const ViewNotes = (count, type, forKids, level, subject) => {

    if (!forKids && type === "Demo") {
      history.push(`/session-document?doc_url=https://clapngobucket.s3.amazonaws.com/documents/learner/Demo+Session+Document_compressed.pdf`)
      return
    }

    const data = {
      sessionCount: count,
      subject: subject,
      // isPeerNote:peerLearningEnabled
    };

    if (level) { data['level'] = level }
    if (forKids) { data['forKids'] = forKids }

    const onSuccess = (data) => {
      var href = data.noteLink
      setNotesLoader(false)
      const split = href.split("=")
      history.push(`/session-document?doc_url=${split[1]}`)
    }
    const onError = (msg) => {
      openSnackbar(msg);
      setNotesLoader(false)
    }
    dispatch(TeacherNotesBySessionCount(data, onSuccess, onError))
  }

  const triggerHotLead = (data) => {
    if (data.isTimeUtc) {
      var joiningDate = utcToLocal(data.sessionDate, data.startTime);
      var joiningDate2 = utcToLocal(data.sessionDate, data.endTime);
      var currentTime = new Date();

      if (joiningDate > currentTime) {
        openSnackbar("Your session not started yet");
      } else if (joiningDate2 < currentTime) {
        openSnackbar("You cant join. Meeting is over");
      } else {
        setHotLeadLoader(true)
        const onSuccess = () => {
          setHotLeadLoader(false)
          openSnackbar("Message sent successfully !");
        }

        const onError = () => {
          setHotLeadLoader(false)
          openSnackbar("Something went's wrong !");
        }

        var leadData;

        if (!sessionData.forKids) {
          leadData = {
            "text": `
        *Name* :${data.learner?.name},
        *Contact* :${data.learner?.phoneNumber},
        *Email* :${data.learner?.email},
        *Tutor Name* :${data.teacher?.name},
        *Meeting Link* :${data.joinMeetingUrl}
        `
          }
        } else {
          leadData = {
            "learnerName": data.learner?.name,
            "parentContact": data.learner?.phoneNumber,
            "parentEmail": data.learner?.email,
            "tutorName": data.teacher?.name,
            "meetingLink": data.joinMeetingUrl
          }
        }

        const hotLeadSub = sessionData.forKids ? "" : data.subjects
        HotLead(leadData, hotLeadSub, onSuccess, onError)
      }
    }
  }
  const joiningDate = utcToLocal(sessionData.sessionDate, sessionData.startTime);
  const joiningDate2 = utcToLocal(sessionData.sessionDate, sessionData.endTime);
  const teacherData = useSelector((state) => state.TeacherReducer.teacher);
  // Get the current time
  const currentTime = new Date();
  const isJoiningTime = joiningDate <= currentTime && currentTime <= joiningDate2;
  const [isPressed, setIsPressed] = useState(false);
  const [open, setOpen] = useState(false);
  const [sessionEndedModalOpen, setSessionEndedModalOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleButtonClick = () => {

    CallLearner();
    setIsPressed(true);
    // You can also reset the state after a short delay if needed
    setTimeout(() => setIsPressed(false), 100);
  };

  const CallLearner = () => {
    const data = {
      from: parseInt(teacherData?.phoneNumber),
      to: parseInt(sessionData?.learner?.phoneNumber),
      sessionId: sessionData?._id

    }
    // console.log(data)
    const onSuccess = (res) => {
      openSnackbar(res.message)
    }

    const onError = (error) => {
      openSnackbar(error.message)
    }

    MakeCall(data, onSuccess, onError)
  }

  // console.log("check", sessionData?.topic)

  // Function to check if the session has ended
  const isSessionEnded = () => {
    const currentTime = new Date();
    const isEnded = joiningDate2 < currentTime;
    return isEnded;
  };

  // // Effect to open the modal when the session ends
  // useEffect(() => {
  //   if (isSessionEnded() && sessionData?.topic?.type == "Milestone" && !sessionData?.topic.isCertificationPassed && sessionData?.topic.isCertificationPassed == undefined) {
  //     setSessionEndedModalOpen(true);
  //   }
  // }, []);

  // const markPassorFail = (result) => {

  //   const data = {
  //     sessionId: sessionData?._id,
  //     isCertificationPassed: result
  //   }
  //   const onSuccess = (msg) => {
  //     openSnackbar(msg)
  //     setOpen(false);

  //   }
  //   const onError = (msg) => {
  //     openSnackbar(msg)
  //     setOpen(false);

  //   }
  //   dispatch(MarkCertificate(data, onSuccess, onError))
  // }

  return (
    <div>

      <div >

        <Dialog onClose={handleClose} maxWidth="md" aria-labelledby="customized-dialog-title" open={open}>
          <DialogContent dividers style={{ width: "400px", padding: "15px" }}>
            <p>Your number will NOT be visible to the learner. Call with the learner will be connected when you receive the incoming call on your number. </p>
          </DialogContent>
          <DialogActions>
            <Button autoFocus onClick={handleClose} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      {
        sessionData?.isFarewellSession ?
          <div className='farewell p-2 '>
            Farewell session
          </div>
          : ""
      }
      <div className={`session-card ${highlight ? 'wiggle' : ''}`}>
        <div className="flex-container">
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <h1 style={{ marginLeft: '10px' }}>Clapingo {sessionData?.topic ? sessionData.topic.name : sessionData.type} {sessionData.forKids ? <small> Kids</small> : sessionData.subjects === "IELTS" ? <small>IELTS</small> : ""} session {sessionData.type === "Regular" ? sessionData.sessionCount : ""}
            <br/>
            {sessionData?.topic  && !sessionData?.isNewFeedback &&
            <div style={{color:"#24A791", fontSize:"0.8rem"}}>( Level: {sessionData?.topic?.level ? sessionData?.topic?.level : sessionData?.topic?.module?.level} )</div>
            }
            </h1>

            {/* {isJoiningTime && (
                <div className='call-icon' onClick={() => CallLearner()}>
                  <i className='fas fa-phone'> Call</i> 
                </div>
              )} */}
            {isJoiningTime && !sessionData?.instantBookingDetails?.id ? (
              <>
                <div style={{ marginLeft: "15%", marginTop: "3%" }}>
                  <button
                    onClick={handleButtonClick}
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      backgroundColor: isPressed ? '#3B9E56' : '#50C878',
                      color: 'white',
                      padding: '5px 10px',
                      borderRadius: '5px',
                      border: 'none',
                      cursor: 'pointer',
                      transition: 'background-color 0.3s',
                    }}
                  >
                    <i className='fas fa-phone' style={{ marginRight: '5px' }}></i> Call
                  </button>
                </div>
                <p onClick={() => handleClickOpen()} style={{ marginLeft: "1%", marginTop: "3%", cursor: "pointer" }}><i className="fas fa-info-circle"></i> </p>


              </>
            ) : null}

          </div>

        </div>

        <div className='info'>
          <div className='info-inner'>
            <p>Time :</p>
            <p>{`${formatAMPM(sessionData.startTime)} - ${formatAMPM(sessionData.endTime)}`}</p>
          </div>
          <div className='info-inner'>
            <p>Date :</p>
            <p>{utcToLocalDateConverter(sessionData.sessionDate, sessionData.endTime)}</p>
          </div>
        </div>
        <div className='media'>
          <p
            onClick={
              () => {
                if(sessionData?.sessionDocument){
                  setNotesLoader(sessionData._id)
                  history.push("session-document?doc_url=" + sessionData?.sessionDocument)
                }else {
                if (!sessionData.forKids) {
                  setNotesLoader(sessionData._id)
                  ViewNotes(sessionData.sessionCount, sessionData.type)
                } else if (sessionData.forKids) {
                  setNotesLoader(sessionData._id)
                  ViewNotes(sessionData.sessionCount - 1, sessionData.type, true, sessionData.learner.currentLevel, sessionData.subjects)
                }
              }
              }
            }

          ><i className="fas fa-download"></i> {notesLoader ? "Fetching notes..." : "Session Notes"}</p>
          <LearnerInfoDialog sessionData={sessionData} type="upcoming" />
        </div>
        <div className='requirement' style={{display:"flex", flexDirection:"row", gap:"3rem"}}>
          <div>
          <p>session requirement :</p>
          {sessionData?.requirements.length > 0 ?
            <div className='requirement-inner'>
              {
                sessionData.requirements.map((data, i) => (
                  <span className='pills' key={i}>{data.name}</span>
                ))
              }
            </div>
            :
            <div className='requirement-inner'>
              <span className='pills'>Not available</span>
            </div>
          }
          </div>
        </div>

       
        {/* {window.innerWidth < 500 ?
          <>
            <p style={{ color: "red", fontSize: "12px", margin: '8px 0' }}>
              Video calls are not supported in mobile web. For best experience use a Laptop or Clapingo App
            </p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <a href="https://play.google.com/store/apps/details?id=com.app.clapingoteacher&hl=en_IN&gl=US">
                <img src={`https://images.weserv.nl/?url=${imgUrl}/gplay.png&w=135&h=40&output=webp`} height="100%" width="100%" alt="mobile.png" />
              </a>

              <a href="https://apps.apple.com/in/app/clapingo-tutor-app/id1607156144">
                <img src={`https://images.weserv.nl/?url=${imgUrl}/apple.png&w=135&h=40&output=webp`} height="100%" width="100%" alt="mobile.png" />
              </a>
            </div>
          </>
          :
          <> */}
            <div className='btn-container'>
              {
                !peerLearningEnabled && 
                <button className='hot-lead' disabled={sessionData.type === "Regular"} onClick={() => triggerHotLead(sessionData)}>
                {hotLeadLoader ? "Loading..." : "Hot lead"}
              </button>
              }
                <button className='join' onClick={() => joinMeeting(sessionData)} disabled={joiningLoader}>{joiningLoader === sessionData._id ? "Joining..." : "Join session"}</button>
            </div>
            {
              !peerLearningEnabled && 
              <div className='btn-container' style={{ display: 'flex', justifyContent: 'space-between' }}>
                {feedbackData && UpcommingSession?.teacher?.distribution === "team_b" &&
                <div style={{ width: 'calc(50% - 5px)' }}> {/* Set width of 50% minus some spacing */}
                   <FeedBackModal sessionData={sessionData} />
                   {/* <button style={{background:"#24A791", color:"white", width:"100%", cursor:"pointer"}} onClick={() => window.open(`https://customer-web-git-staging-clapingo.vercel.app/language-proficiency-test/Report?${sessionData?.learner?._id}`, '_blank')}> AI Feedback</button> */}
                </div>
                }
                {feedbackData && UpcommingSession?.teacher?.distribution === "team_b" ?
              <div style={{ width: 'calc(50% - 5px)' }}> {/* Set width of 50% minus some spacing */}
                <CancelSessionDialog sessionData={sessionData} width={sessionData?.subjects === "IELTS" ? "" : "100%"} setCancelBtnLoader={setCancelBtnLoader} openSnackbar={openSnackbar} dispatch={dispatch} cancelBtnLoader={cancelBtnLoader} />
              </div>
               :
              <div style={{ width: "100%" }}> {/* Set width of 50% minus some spacing */}
                <CancelSessionDialog sessionData={sessionData} width={sessionData?.subjects === "IELTS" ? "" : "100%"} setCancelBtnLoader={setCancelBtnLoader} openSnackbar={openSnackbar} dispatch={dispatch} cancelBtnLoader={cancelBtnLoader} />
              </div>
              }
              {
                sessionData?.subjects === "IELTS" ?
                  <button className='link' onClick={() => joinViaLink(sessionData)}>
                    Open zoom
                  </button>
                  : ""
              }
            </div>
            }
{/* 
          </>
        } */}
        {
          sessionData?.hasPreviouslyTakenSessionWithLearner ?
            <div className='previous-session'>
              <i className="fas fa-info-circle fa-xl"></i><p>You have taken a session with this learner Earlier on {moment(sessionData?.lastSessionDate).format("DD-MM-YYYY")}</p>
            </div>
            : ""
        }
      </div>
    </div>
  )
}


export const PastSessionCard = ({ sessionData }) => {
  const dispatch = useDispatch()
  const [recordingLoader, setRecordingLoader] = useState(false);
  const [notesLoader, setNotesLoader] = useState(false)
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [blockLearnerLoader, setBlockLearnerLoader] = useState(false);
  const [hotLeadLoader, setHotLeadLoader] = useState(false);
  const [blockedLearners, setBlockedLearners] = useState([]);
  const [markedTechFailure, setMarkedTechFailure] = useState(Boolean(sessionData?.feedbackData?.teachIssuefeedBack?.reportTechIssue));
  const [sessionEndedModalOpen, setSessionEndedModalOpen] = useState(false);
  const [peerLearningEnabled, setPeerLearningEnabled] = useState(false);
  const history = useHistory();

  const BlockLearners = useSelector((state) => state.TeacherReducer.blockedLearners);
  const teacherData = useSelector((state) => state.TeacherReducer.teacher);

  useEffect(() => {
    if(teacherData){
    const peerLearningSubject = teacherData?.subjects?.filter(data => data?.peerLearning === true)
    if(peerLearningSubject?.length > 0){
      setPeerLearningEnabled(true)
    }
  }
  }, [teacherData])

  useEffect(() => {
    if (BlockLearners) {
      var blocked = [];
      BlockLearners.map((data) => {
        blocked.push(data._id);
      });
      setBlockedLearners(blocked);
    }
  }, [])


  const ViewNotes = (count, type, forKids, level, subject) => {

    if (!forKids && type === "Demo") {
      history.push(`/session-document?doc_url=https://clapngobucket.s3.amazonaws.com/documents/learner/Demo+Session+Document_compressed.pdf`)
      return
    }

    const data = {
      sessionCount: count,
      subject: subject,
      // isPeerNote:peerLearningEnabled
    };

    if (level) { data['level'] = level }
    if (forKids) { data['forKids'] = forKids }

    const onSuccess = (data) => {
      var href = data.noteLink
      setNotesLoader(false)
      const split = href.split("=")
      history.push(`/session-document?doc_url=${split[1]}`)
    }
    const onError = (msg) => {
      openSnackbar(msg);
      setNotesLoader(false)
    }
    dispatch(TeacherNotesBySessionCount(data, onSuccess, onError))
  }

  const viewRecording = (sessionData) => {
    const val = {
      meetingId: sessionData.meetingNo,
    };
    const onSuccess = (data) => {
      setRecordingLoader(null);
      {/* console.log(data.recording_files); */}
      window.open(data.recording_files[0].play_url, "_blank");
    };
    const onError = () => {
      setRecordingLoader(null);
      openSnackbar("Recording not found for this session !!");
    };
    if(sessionData.meetingRecording) 
    window.open(`https://clapingo-testing.netlify.app/recordings?link=${sessionData.meetingRecording}`, "_blank");
    else{
      setRecordingLoader(sessionData._id);
      dispatch(ZoomMeetingRecording(val, onSuccess, onError));
    }
  };

  const blockLearner = (sessionData) => {

    setBlockLearnerLoader(sessionData._id);
    const val = {
      learnerId: sessionData.learner?._id,
    };

    const onSuccess = (msg) => {
      setBlockLearnerLoader(null);
      blockedLearners.push(sessionData.learner._id);
      openSnackbar(msg);
    };
    const onError = (msg) => {
      setBlockLearnerLoader(null);
      openSnackbar(msg);
    };
    dispatch(Blocklearner(val, onSuccess, onError));
  };

  const unblockLearner = (sessionId, learnerId) => {
    setBlockLearnerLoader(sessionId);

    const val = {
      learnerId: learnerId,
    };
    const onSuccess = (msg) => {
      setBlockLearnerLoader(null);
      setBlockedLearners(
        blockedLearners.filter((learner) => learner !== learnerId)
      );
      openSnackbar(msg);
    };
    const onError = (msg) => {
      setBlockLearnerLoader(null);
      openSnackbar(msg);
    };
    dispatch(UnBlocklearner(val, onSuccess, onError));
  };

  const triggerHotLead = (data) => {
    if (data) {
      {/* console.log("function called") */}
      var meetingEndTime = utcToLocal(data.sessionDate, data.endTime);
      let currentTime = new Date();
      currentTime.setHours(currentTime.getHours())

      var diff = Math.round((currentTime - meetingEndTime) / 3600000);

      if (diff > 24) {
        openSnackbar("Hot lead time exceed !");
      } else {
        setHotLeadLoader(true)
        const onSuccess = () => {
          setHotLeadLoader(false)
          openSnackbar("Message sent successfully !");
        }

        const onError = () => {
          setHotLeadLoader(false)
          openSnackbar("Something went's wrong !");
        }
        var leadData;
        if (!sessionData.forKids) {
          leadData = {
            "text": `
              *Name* :${data.learner?.name},
              *Contact* :${data.learner?.phoneNumber},
              *Email* :${data.learner?.email},
              *Tutor Name* :${data.teacher?.name},
              *Meeting Link* :${data.joinMeetingUrl}
              `
          }
        }

        const hotLeadSub = sessionData.forKids ? "" : data.subjects
        HotLead(leadData, hotLeadSub, onSuccess, onError)
      }
    }
  }

  // useEffect(() => {
  //   // setSessionEndedModalOpen(true);
  //   // console.log("CONDITION",sessionData, sessionData?.topic?.isCertificationPassed )
  //   if (sessionData?.topic?.type && sessionData?.topic?.isCertificationPassed == undefined) {
  //     setSessionEndedModalOpen(true);
  //     console.log("current id", sessionData._id)
  //   }
  // }, []);

  // const markPassorFail = (result) => {

  //   const data = {
  //     sessionId: sessionData._id,
  //     isCertificationPassed: result
  //   }
  //   const onSuccess = (msg) => {
  //     console.log(msg)
  //     openSnackbar(msg)
  //     setSessionEndedModalOpen(false);
  //     // setOpen(false);

  //   }
  //   const onError = (msg) => {
  //     console.log("error",msg)
  //     openSnackbar(msg)
  //     setSessionEndedModalOpen(false);
  //     // setOpen(false);

  //   }
  //   dispatch(MarkCertificate(data, onSuccess, onError))
  // }

  // console.log("CONDITIONS", sessionData?.topic?.type && sessionData?.topic?.isCertificationPassed == undefined)
  return (
    <div className={(sessionData.cancelBy === teacherData._id) ? 'wrap' : ""}>
      


      <div className={`session-card ${(sessionData.cancelBy === teacherData._id) ? 'cancelled' : ""} `}>
        <h1>Clapingo {sessionData?.topic ? sessionData.topic.name : sessionData.type} {sessionData.forKids ? <small> Kids</small> : sessionData.subjects === "IELTS" ? <small>IELTS</small> : ""} session {sessionData.type === "Regular" ? sessionData.sessionCount : ""}
        {sessionData?.topic && !sessionData?.isNewFeedback &&
        <div style={{color:"#24A791", fontSize:"0.8rem"}}>( Level: {sessionData?.topic?.level ? sessionData?.topic?.level : sessionData?.topic?.module?.level} )</div>
        }
        </h1> 
        <div className='info'>
          <div className='info-inner'>
            <p>Time :</p>
            <p>{`${formatAMPM(sessionData.startTime)} - ${formatAMPM(sessionData.endTime)}`}</p>
          </div>
          <div className='info-inner'>
            <p>Date :</p>
            <p>{utcToLocalDateConverter(sessionData.sessionDate, sessionData.endTime)}</p>
          </div>
        </div>

        <div className='media'>
          <p
            onClick={
              () =>  {
                if(sessionData?.sessionDocument){
                  setNotesLoader(sessionData._id)
                  history.push("session-document?doc_url=" + sessionData?.sessionDocument)
                }else {
                if (!sessionData.forKids) {
                  setNotesLoader(sessionData._id)
                  ViewNotes(sessionData.sessionCount, sessionData.type)
                } else if (sessionData.forKids) {
                  setNotesLoader(sessionData._id)
                  ViewNotes(sessionData.sessionCount - 1, sessionData.type, true, sessionData.learner.currentLevel, sessionData.subjects)
                }
              }
              }
            }
          ><i className="fas fa-download"></i> {notesLoader ? "Fetching notes..." : "Session Notes"}</p>
          <LearnerInfoDialog sessionData={sessionData} type="past" />
        </div>
        <div className='media'>
          <p onClick={() => viewRecording(sessionData)}><i className="fas fa-video"></i> {recordingLoader ? "Loading..." : "Recording"}</p>
        </div>

        {
          !peerLearningEnabled &&
          <>
                  <div className='requirement'>
          <p><i className="fas fa-comment-alt"></i> Learner feedback :</p>
          {!sessionData.cancelBy && sessionData?.ratingData?.QA.length > 0 ?
            <div className='requirement-inner'>
              <span className='pills'><strong>{sessionData.ratingData.rating}</strong> <i className="fa-solid fa-star"></i></span>
              {
                sessionData.ratingData.QA.map((data, i) => (
                  <span className='pills' key={i}>{data.answer}</span>
                ))
              }
            </div>
            :
            <div className='requirement-inner'>
              <span className='pills'>Not available</span>
            </div>
          }
        </div>
        <div className='btn-container'>
          <button className='block' onClick={() => {
            if (blockedLearners.includes(sessionData.learner?._id)) {
              unblockLearner(sessionData._id, sessionData.learner._id)
            } else {
              blockLearner(sessionData)
            }
          }}>
            {
              blockLearnerLoader === sessionData._id ? "Loading..." : blockedLearners.includes(sessionData.learner?._id) ? "Unblock lerner" : "Block learner"
            }
          </button>
          {
            sessionData?.forKids ?
              <SessionScore sessionData={sessionData} />
              :
              <button style={{ background: "#4dc591", cursor: "pointer" }} disabled={sessionData?.type === "Regular" || (sessionData?.subjects !== "English")} onClick={() => triggerHotLead(sessionData)}> Hot lead </button>
          }
        </div>
        <div className='btn-container'>
          {sessionData?.feedbackData?.teachIssuefeedBack?.reportTechIssue || markedTechFailure || !sessionData?.feedbackData ?
            <MarkTechFailureModal
              SessionId={sessionData._id}
              feedbackData={sessionData?.feedbackData ? sessionData.feedbackData : null}
              subject={sessionData?.subjects}
              sessionType={sessionData?.type}
              sessionCount={sessionData?.sessionCount}
              markedTechFailure={markedTechFailure}
              setMarkedTechFailure={setMarkedTechFailure}
            /> :
            null}
              {!sessionData?.feedbackData?.teachIssuefeedBack
                ?.reportTechIssue && !markedTechFailure ? (
                sessionData?.isNewFeedback ? (
                  <CurriculumFeedbackModal
                    SessionId={sessionData._id}
                    feedbackData={sessionData?.feedbackData ? sessionData.feedbackData: null}
                    subject={sessionData?.subjects}
                    sessionType={sessionData?.type}
                    sessionCount={sessionData?.sessionCount}
                    forKids={sessionData?.forKids}
                  />
                ) : (
                  <FeedbackModalV2
                    SessionId={sessionData._id}
                    feedbackData={sessionData?.feedbackData ? sessionData.feedbackData: null}
                    subject={sessionData?.subjects}
                    sessionType={sessionData?.type}
                    sessionCount={sessionData?.sessionCount}
                    forKids={sessionData?.forKids}
                  />
                )
              ) : null}
        </div>
          </>
        }

      </div>
      <div className={(sessionData.cancelBy === teacherData._id) ? 'override' : 'not-canceled'} >
        {(sessionData.cancelBy === teacherData._id) ? <h3>Cancelled!</h3> : ""}
      </div>
    </div>
  )
}
