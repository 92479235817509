import api from "../apis/Authapi";
import * as constant from "../constants/TeacherConstants";

export const VerifyOtp = (otp, onSuccess, onError) => (dispatch) => {
  //SIGNUP LEARNER
  console.log(otp);
  api
    .Authontication()
    .VerifyOtp(otp)
    .then((res) => {
      console.log(res.data);
      localStorage.setItem("DATA", JSON.stringify(res.data.learner));
      onSuccess(res.data.message);
      //   dispatch({
      //       type: "FETCH_ALL",
      //       payload: res.data
      //   })
    })
    .catch((err) => {
      //console.log(err.response.data.message);
      onError(err.response.data.message);
    });
};
export const ResendOtp = (number, onSuccess, onError) => (dispatch) => {
  //SIGNUP LEARNER

  api
    .Authontication()
    .ResendOtp(number)
    .then((res) => {
      console.log(res.data);
      onSuccess();
      console.log(res.data);
      //   dispatch({
      //       type: "FETCH_ALL",
      //       payload: res.data
      //   })
    })
    .catch((err) => {
      console.log(err.response.data.message);
      onError(err.response.data.message);
    });
};

export const Setpassword = (data, onSuccess, onError) => (dispatch) => {
  //SIGNUP LEARNER
  console.log(data);
  api
    .Authontication()
    .setPassword(data)
    .then((res) => {
      console.log(res.data);
      onSuccess(res.data.message);
      //   dispatch({
      //       type: "FETCH_ALL",
      //       payload: res.data
      //   })
    })
    .catch((err) => {
      console.log(err);
      onError("Somthing went wrong");
      //err.response.data.message
    });
};


export const getNotes = (data, onSuccess) => ( dispatch) => {
  api
    .Authontication()
    .GetNotes(data)
    .then((res) => {
      console.log(res.data);
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err);
    });
};

//LOAD USER
export const relode = (data) => (dispatch) => {
  dispatch({
    type: constant.LOAD_USER,
    payload: data,
  });
};

//TEACHER AUTH
export const askteacherpassword = (data, onSuccess, onError) => (dispatch) => {
  //SIGNUP LEARNER
  api
    .Authontication()
    .askteacherpassword(data)
    .then((res) => {
      console.log(res.data);
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err.response.data.message);
      onError(err.response.data.message);
    });
};

export const TeacherForgotPassword = (data, onSuccess, onError) => (
  dispatch
) => {
  //SIGNUP LEARNER
  console.log(data);
  api
    .Authontication()
    .teacherForfotPassword(data)
    .then((res) => {
      console.log(res.data);
      onSuccess(res.data.message);
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message);
    });
};

export const VerifyTeacherOtp = (data, onSuccess, onError) => (dispatch) => {
  //SIGNUP LEARNER
  console.log(data);
  api
    .Authontication()
    .verifyteacherOtp(data)
    .then((res) => {
      console.log(res.data);
      localStorage.setItem("USER", JSON.stringify(res.data.teacher));
      onSuccess(res.data.message);
    })
    .catch((err) => {
      console.log(err.response.data.message);
      onError(err.response.data.message);
    });
};

export const UpdatePassword = (data, onSuccess, onError) => (dispatch) => {
  //SIGNUP LEARNER
  api
    .Authontication()
    .updatePassword(data)
    .then((res) => {
      const user = JSON.parse(localStorage.getItem("USER"))
      localStorage.setItem("TEACHER", JSON.stringify(user));
      localStorage.setItem("TOKEN", JSON.stringify(user.token));
      localStorage.removeItem("USER")
      onSuccess();
      window.location.reload()
    })
    .catch((err) => {
      console.log(err.response.data.message);
      onError(err.response.data.message);
    });
};

export const TeacherLogin = (data, onSuccess, onError) => (dispatch) => {
  //SIGNUP LEARNER
  console.log(data);
  api
    .Authontication()
    .Teacherlogin(data)
    .then((res) => {
      console.log(res.data);
      localStorage.setItem("TEACHER", JSON.stringify(res.data.teacher));
      localStorage.setItem("TOKEN", JSON.stringify(res.data.token));
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message);
    });
};

export const CurrentTeacher = (data) => (dispatch) => {
  //SIGNUP LEARNER
  api
    .Authontication()
    .relodeTeacher(data)
    .then((res) => {

      var teacherData = {
        ...res.data.teachers[0],
          teacherRefferalBonus:res.data.teacherRefferalBonus,
          teacherRefferalBonusKids:res.data.teacherRefferalBonusKids,
          teacherRefferalToBonus:res.data.teacherRefferalToBonus,
          teacherRefferalToBonusKids:res.data.teacherRefferalToBonusKids,
          penaltyAmount:res.data.penaltyAmount
      }

      localStorage.setItem(
        "TEACHER",
        JSON.stringify(teacherData)
      );

      dispatch({
        type: constant.LOAD_USER,
        payload: teacherData,
      });

    })
    .catch((err) => {
      console.log(err);
    });
};

export const UploadProfile = (data,onSuccess,onError) => (dispatch) => {
  api
    .Authontication()
    .uploadProfile(data)
    .then((res) => {
      console.log(res.data.message)
      onSuccess(res.data.message)
    })
    .catch((err) => {
      onError(err.response.data.message)
      console.log(err.response.data);
    });
};

export const changeTeacherPassword = (data,onSuccess,onError) => (dispatch) => {
  api
    .Authontication()
    .changeTeacherPassword(data)
    .then((res) => {
      console.log(res.data)
      onSuccess(res.data.message)
    })
    .catch((err) => {
      onError(err.response.data.message)
      //err.response.data.message
      console.log(err.response);
    });
};
export const TeacherQuery = (data,onSuccess,onError) => (dispatch) => {
  api
    .Authontication()
    .Query(data)
    .then((res) => {
      console.log(res.data)
      onSuccess(res.data.message)
    })
    .catch((err) => {
      onError(err.response.data.message)
      //err.response.data.message
      console.log(err.response);
    });
};

export const TeacherReferralProfile = (id,onSuccess,onError) => {
  api
    .Authontication()
    .referralProfile(id)
    .then((res) => {
      onSuccess({...res.data.teacherData,teacherRefferalToBonus:res.data.teacherRefferalToBonus})
    })
    .catch((err) => {
      onError(err.response.data.message)
      //err.response.data.message
      console.log(err.response);
    });
};


export const MagicLogin = (token,onSuccess,onError) => {
  api
    .Authontication()
    .magicLogin(token)
    .then((res) => {
      localStorage.setItem("TEACHER", JSON.stringify(res.data.teacher));
      localStorage.setItem("TOKEN", JSON.stringify(res.data.teacher.token));
      onSuccess(res)
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message)
    });
};

export const AddBankDetails = (token,onSuccess,onError) => {
  api
    .Authontication()
    .addBankDetails(token)
    .then((res) => {
      onSuccess(res)
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message)
    });
};

export const GetBankDetails = (onSuccess,onError) => {
  api
    .Authontication()
    .getBankDetails()
    .then((res) => {
      onSuccess(res.data)
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message)
    });
};

export const VerifyBankDetails = (data, onSuccess,onError) => {
  api
    .Authontication()
    .verifyBankAccount(data)
    .then((res) => {
      onSuccess(res.data)
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message)
    });
};


export const AskForVerification = (data, onSuccess,onError) => {
  api
    .Authontication()
    .askForVerification(data)
    .then((res) => {
      onSuccess(res.data)
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message)
    });
};

export const FreshchatRestoreId = async (data, onSuccess, onError) => {
  api
    .Authontication().updateTeacherRestoreId(data)
    .then((res) => {
      if (res.status === 200) {
        onSuccess(res.data)
      }else {
        onError(res.data.message)
      }
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message)
    });
}

export const ResourcesUpload= (data,onSuccess,onError) => {
  api
    .Authontication()
    .resourceUpload(data)
    .then((res) => {
      console.log(res.data)
      onSuccess(res.data)
    })
    .catch((err) => {
      onError(err.response.data.message)
      console.log(err.response);
    });
};

export const getTags = (onSuccess, onError) => {
  api
  .Authontication()
  .tags()
    .then((res) => {
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      onError(err);
    });
};

export const TeacherSignUp = (data, onSuccess, onError) => {
  api
    .Authontication()
    .teacherSignUp(data)
    .then((res) => {
      console.log(res)
      onSuccess(res.data);
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.data.message);
    });
};

export const getTeacherApplication = (id, onSuccess, onError) => {
  api
    .Authontication()
    .getTeacherApplicationData(id)
    .then((res) => {
      onSuccess(res.data.application);
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.message);
    });
};

export const updateInstantBookingOption = (data, onSuccess, onError) => dispatch => {
  api
    .Authontication()
    .updateInstantBooking(data)
    .then((res) => {
      dispatch({
        type: "UPDATE_INSTANT_BOOKING",
        payload: data
      })
      if(data.enabled){
        let teacher = JSON.parse(localStorage.getItem("TEACHER"))
        teacher = {...teacher, instantBooking: data}
        localStorage.setItem("TEACHER", JSON.stringify(teacher));
      }
      onSuccess(res.data.message);
    })
    .catch((err) => {
      console.log(err.response);
      onError(err.response.message);
    });
};

export const SaveFCMTokenInDB = (token) => {
  api
    .Authontication()
    .uploadProfile({webnotificationid: token})
    .then((res) => {
      localStorage.setItem(
        "TEACHER",
        JSON.stringify(res.data.teacher)
      );
    })
    .catch((err) => {
      console.log(err.response.data);
    });
};

export const ApplyCuponCode = (data, onSuccess, onError) => (dispatch) => {
  //SIGNUP LEARNER
  api
    .Authontication()
    .CuponCode(data)
    .then((res) => {
      console.log(res);
      if (res.data.statusCode === 500) {
        onError(res);
      } else {
        onSuccess(res.data.influencers);
      }
    })
    .catch((err) => {
      console.log(err);
      if (onError) {
        if (err.code === 'ECONNABORTED'){
          onError("Timeout");
        }else{
        console.log(err.response);
        onError(err.response.data.message);
        }
      }
    });
};


export const GeneratePaymentLink = (data,onSuccess,onError) => (dispatch) => {
  api
    .Authontication()
    .generatePaymentLink(data)
    .then((res) => {
      console.log(res.data);
      onSuccess(res.data);
    })
    .catch((err) => {
      if (err.code === 'ECONNABORTED'){
        onError("Timeout");
      }else{
      console.log(err.response);
      onError(err.response);
      }
    });
};

export const getPlan = (onSuccess) => (dispatch) => {
  //SIGNUP LEARNER
  api
    .Authontication()
    .getplan()
    .then((res) => {
      console.log(res.data);
      onSuccess(res.data);
    })
    .catch((err) => {
      if (err.code === 'ECONNABORTED'){
        console.log("Timeout");
      }else{
      console.log(err.response);
      }
    });
};

export const GetPlans = (data,onSuccess) => (dispatch) => {
  api
    .Authontication().getPlans(data)
    .then((res) => {
        console.log(res.data.plansData)
       onSuccess(res.data.plansData)
    })
    .catch((err) => {
      console.log(err);
    });
};
