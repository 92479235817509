import React from "react";
import {
  Grid,
  ListItemText,
  Typography,
  IconButton,
  Button,
} from "@mui/material";
import Info from "@material-ui/icons/InfoOutlined";
import { useRef } from "react";
import { useState } from "react";
import { useEffect } from "react";
import SendIcon from "@material-ui/icons/Send";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import { v4 as uuidv4 } from "uuid";

import io from "socket.io-client";
import { GetAllMessagesByRoomID, SendFile, UpdateMessage } from "../../redux/actions/ChatAction";
import { useHistory, useParams } from "react-router-dom";
import Messages from "./Messages";
import DateTimePicker from "./DatePicker";
import { converter, formatTimeslotLocalToUTC, sessionBookingDate } from "../../helper/sessionHelper";
import { useSnackbar } from "react-simple-snackbar";

const ChatSection = ({showRecentChatOnTop, selectedLearner, chatters, setChatters, selectedLearnerId}) => {
  const {roomId} = useParams();
  const [msgText, setMsgText] = useState("");

  const messagesEndRef = useRef(null);

  const [socket, setSocket] = useState(null);
  const [messages, setMessages] = useState(null);
  const [message, setMessage] = useState("");
  const [subscriptions, setSubscriptions] = useState(null);
  const [purchasedSessions, setPurchasedSessions] = useState(null);
  const [messageLoader, setMessageLoader] = useState(true);
  const localStorageToken = JSON.parse(localStorage.getItem("TOKEN"));
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");
  const [pickerVisible, setPickerVisible] = useState(false);
  const [sessionData, setSessionData] = useState(null);
  const [openSnackbar, closeSnackbar] = useSnackbar();

  const {timezone} = JSON.parse(localStorage.getItem("TIMEZONE"));

  const history = useHistory();
  // console.log(process.env.CHAT_SERVER)

  useEffect(() => {
    const newSocket = io(process.env.REACT_APP_CHAT_SERVER, {
      auth: { token: localStorageToken },
    });
    setSocket(newSocket);
    const onSuccess = (res) => {
      setMessageLoader(false);
      setMessages(res?.data?.messages);
      setSubscriptions(res?.data?.subscriptions);
      setPurchasedSessions(res?.data?.purchasedSessionsData);
    };
    const onError = (err) => {
      console.log(err);
    };
    if (roomId) {
      GetAllMessagesByRoomID(roomId, onSuccess, onError);
      newSocket.emit("join_room", { chatroomId: roomId });
      newSocket.emit("read", { chatroomId: roomId });
    }

    return () => newSocket.close();
  }, [localStorageToken, roomId, setSocket]);

  useEffect(() => {
    const msgEventListener = (message)=>{
      const msgArr = [];
      msgArr.push(message);
      setMessages(messages.concat(msgArr));
    }
    const readEventListener = (message) => {
      console.log("read", message);
      const index = chatters.findIndex((chatter)=>chatter._id===message.chatroomId);
      let newChatters = [...chatters];
      newChatters[index].teacherUnread = 0;
      setChatters(newChatters);
    }
    if (socket && messages) {
      console.log("joining new_message event");
      socket.on("new_message", msgEventListener);
      socket.on("read", readEventListener);
    }

    return ()=>{
      console.log("socket.off");
      if(socket) {
        socket.off("new_message", msgEventListener);
        socket.off("read", readEventListener);
      }  
    };
  }, [socket, messages]);

  const sendMessage = (sessionData) => {
    let msg = {
      chatroomId: roomId,
      message: msgText,
      role: "Teacher",
      msgType: "Session",
    };

      let startTime = selectedTime?.split(" - ")[0];
      let selectedDateInUTC = converter(`${selectedDate}T${startTime}:00`,timezone);
      msg["sessionDate"] = selectedDateInUTC
      let selectedTimeInUTC = formatTimeslotLocalToUTC(selectedTime);
      msg["sessionTime"] = selectedTimeInUTC?.split("-").join(" - ");
      msg["subject"] = sessionData?.subject;
      msg["forKids"] = sessionData?.forKids;

    console.log("mylogmsg",msg, selectedDate, selectedTime);
    socket.emit("chatroom_message", msg);
    setMsgText("");
    setSessionData(null);
    setSelectedDate("");
    setSelectedTime("");
    showRecentChatOnTop(roomId);
  };

  useEffect(() => {
      if (messagesEndRef.current) {
        messagesEndRef?.current?.addEventListener("DOMNodeInserted", (event) => {
          console.log("event target", event.currentTarget, event.target, event.target.clientHeight);
          const { currentTarget: target } = event;
          target.scroll({ top: target.scrollHeight+300, behavior: "smooth" });
          console.log("scrolling");
        });
      }

      // const keyUpEventListener = (event) => {
      //   if (event.keyCode === 13) { 
      //     event.preventDefault();
      //     document.getElementById("sendBtn").click();
      //   }
      // }
      // const msgInput = document.getElementById("msgInput");
      // msgInput.addEventListener("keyup", keyUpEventListener)

      // return ()=>msgInput.removeEventListener("keyup", keyUpEventListener);
    }, []);

    const sendFile = (e) => {
      const onError = (err) => {
        console.log(err);
      }
      const file = e.target.files[0];
      console.log("formData fie", file, roomId);
      SendFile(file, roomId, onError);
    }

    const handleSuggest = () => {
      const data = {
        messageId: sessionData?._id,
        status: "Rejected"
      }
  
      const onSuccess = () => {
        sendMessage(sessionData);
        openSnackbar("Session is suggested");
        let updatedMessages = messages.map((msg)=>{
          if(msg._id===sessionData?._id) msg.status="Rejected"
          return msg;
        })
        setMessages(updatedMessages)
      }
  
      const onError = (err) => {
        openSnackbar(err);
      }
  
      UpdateMessage(onSuccess, onError, data)
    }
  
    const onClose = () => {
      setSessionData(null);
      setSelectedDate("");
      setSelectedTime("");  
    }
  


   const formattedMsg = msgText?.replaceAll("\n", '<br/>'); 

  return (
    <Grid className="chat_section" item xs={12} sm={7} md={9}>
      <Grid container className="chat_header">
      <Button onClick={()=>{history.goBack()}}>
        <ArrowBackIosIcon/>
      </Button>
        <Typography variant="h6" align="center" className="heading">
          {selectedLearner}
        </Typography>
      </Grid>
      <div className="messagearea" ref={messagesEndRef}>
        <Messages 
          messages={messages}
          setMessages={setMessages}
          setSessionData={setSessionData}
          subscriptions={subscriptions}
          purchasedSessions={purchasedSessions}
          selectedLearner={selectedLearner}
          setMessage={setMessage}
        />
      </div>
      {sessionData && msgText ? <div className="msgText" style={{padding: "10px 20px", fontSize: 14}} dangerouslySetInnerHTML={{__html: formattedMsg}} >{}</div> : null}


      {sessionData ? 
        <Grid gap={1} container wrap="nowrap" className="send_msg">

      <DateTimePicker
        onClose={onClose} 
        subscriptions={subscriptions} 
        sessionData={sessionData}
        learner={selectedLearnerId} 
        selectedDate={selectedDate}
        setSelectedDate={setSelectedDate}
        setSelectedTime={setSelectedTime}
        selectedTime={selectedTime}
        bookingMsg={formattedMsg}
        setBookingMsg={setMsgText}
        handleSuggest={handleSuggest}
        message={message}
      /></Grid>: null}   
        {/* <Grid item xs={true}>
          <input
            className="msg_input"
            id="msgInput"
            placeholder="Your message"
            value={msgText}
            onChange={(e) => setMsgText(e.target.value)}
          />
        </Grid> */}
          {/* {
            pickerVisible?
                  <DateTimePicker 
                    onClose={()=>setPickerVisible(false)} 
                    duration={activeSubscription?.plan?.classDuration} 
                    learner={selectedLearnerId} 
                    selectedDate={selectedDate}
                    setSelectedDate={setSelectedDate}
                    setSelectedTime={setSelectedTime}
                    selectedTime={selectedTime}
                    setBookingMsg={setMsgText}
                    sendMessage={sendMessage}
                  />              
            : activeSubscription && (activeSubscription?.subscriptionType === "quick_learner_mode" || (activeSubscription?.availableWeeklySession > 0 && activeSubscription?.availableSession > 0)) ?
              <Grid>
                  <Button
                  sx={{minWidth: "auto", textTransform: "inherit"}}
                  variant="outlined" 
                  className="request_btn"                 
                  onClick={() => {
                    setPickerVisible(true);
                  }}
                  >
                    Request to schedule a session
                  </Button>

              </Grid>
              :
              <div>Learner doesn't have active subscription</div>
          } */}
        {/* {
          !pickerVisible?
          <>
        <Grid xs="auto" align="center" sx={{position: "relative"}}>
          <Button sx={{minWidth: "auto"}} style={{width: "100%", height: "100%", position: "absolute", left: 0, cursor: "pointer", padding: 0}} ><input type="file" style={{width: "100%", height: "100%", cursor: "pointer", opacity: 0, textIndent: "-100px", fontSize: 0}} onChange={sendFile} /></Button>
          <Button sx={{minWidth: "auto", pointerEvents: "none"}} color="inherit">
            <AttachFileIcon/>
          </Button>
        </Grid>
        </>
        :""
        } */}
    </Grid>
  );
};

export default ChatSection;
