import useStars from "stars-rating-react-hooks";

const StarRating = ({
  feedbackInfo,
  setFeedbackInfo,
  data,
  subIndex,
  subData,
}) => {
  const { stars, getStarProps } = useStars({
    totalStars: 5,
    initialSelectedValue: subData[Object.keys(subData)[0]],
    renderFull: "★",
    renderEmpty: "☆",
  });

  return (
    <div>
      {stars?.map((star, i) => (
        <span
          key={i}
          {...getStarProps(i, {
            style: {
              fontSize: "30px",
              color: "gold",
              cursor: "pointer",
            },
            onClick: (event, ratedValue) => {
              setFeedbackInfo({
                ...feedbackInfo,
                [data]: feedbackInfo[data].map((subData, index) => {
                  if (index === subIndex) {
                    return {
                      ...subData,
                      [Object.keys(subData)[0]]: ratedValue,
                    };
                  }
                  return subData;
                }),
              });
            },
          })}
        >
          {star}
        </span>
      ))}
    </div>
  );
};

export default StarRating;
