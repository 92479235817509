import React, { Fragment, useEffect, useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import Alert from "@material-ui/lab/Alert";
import { useSnackbar } from "react-simple-snackbar";
import { useDispatch } from "react-redux";
import { Teacherfeedback } from "../redux/actions/SessionAction";
import StarRating from "./StarRating";

const CurriculumFeedbackModal = ({
  SessionId,
  feedbackData,
  subject,
  sessionType,
  feedbackModalId,
  forKids,
}) => {
  const dispatch = useDispatch();

  const points = ["non_verbal", "verbal_speech", "fundamentals", "other"];
  const [feedbackInfo, setFeedbackInfo] = useState({
    non_verbal: [
      { body_language: 0 },
      { facial_expressions: 0 },
      { listening: 0 },
      { comprehension: 0 },
    ],
    verbal_speech: [
      { fluency: 0 },
      { pronunciation: 0 },
      { verbal_modulation: 0 },
    ],
    fundamentals: [
      { grammer: 0 },
      { vocabulary: 0 },
      { content: 0 },
      { novelty_of_thought: 0 },
    ],
  });
  const [selectedPoints, setSelectedPoints] = useState([]);
  const [openSnackbar, closeSnackbar] = useSnackbar();
  const [loder, setLoder] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState("");
  const [feedbackText, setFeedbackText] = useState(""); //custom feedback text

  useEffect(() => {
    if (!feedbackData && SessionId === feedbackModalId) {
      setOpen(true);
    }
  }, [feedbackData, feedbackModalId]);

  const handleClose = () => {
    setOpen(false);
  };

  function validateFeedback(feedback) {
    let isValid = true;
    let totalNonVerbal = 0;
    let totalVerbalSpeech = 0;
    let totalFundamentals = 0;
    for (let key in feedback) {
      for (let subKey in feedback[key]) {
        if (feedback[key][subKey] > 0) {
          if (key === "non_verbal") {
            totalNonVerbal++;
          } else if (key === "verbal_speech") {
            totalVerbalSpeech++;
          } else if (key === "fundamentals") {
            totalFundamentals++;
          }
        }
      }
    }
    if (
      totalNonVerbal === 0 ||
      totalVerbalSpeech === 0 ||
      totalFundamentals === 0
    ) {
      setError("Please rate atleast one sub attribute in every attribute");
      isValid = false;
    }
    if (isValid) {
      submitFeedback(feedback);
    }
  }

  function formatString(input) {
    return input
      .split("_") // Split the string by underscores
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize the first letter of each word
      .join(" "); // Join the words with spaces
  }

  const transformFeedbackData = (feedbackInfo) => {
    let feedback = {};
    for (let key in feedbackInfo) {
      feedback[key] = {};
      feedbackInfo[key].forEach((subData) => {
        feedback[key][Object.keys(subData)[0]] =
          subData[Object.keys(subData)[0]];
      });
    }
    validateFeedback(feedback);
  };

  const submitFeedback = (feedback) => {
    setLoder(true);
    let data = {
      sessionId: SessionId,
    };
    data = { ...data, ...feedback };
    if (feedbackText) {
      data["others"] = feedbackText;
    }
    data["isNewFeedback"] = true;
    const onSuccess = (msg) => {
      setOpen(false);
      setLoder(false);
      openSnackbar(msg);
      setFeedbackText("");
    };
    const onError = (msg) => {
      setOpen(false);
      setLoder(false);
      openSnackbar(msg);
    };

    dispatch(Teacherfeedback(data, onSuccess, onError));
  };

  return (
    <>
      <button
        className="feedback"
        style={{ width: !feedbackData ? "48%" : "100%" }}
        onClick={() => setOpen(true)}
        disabled={feedbackData || forKids}
      >
        {feedbackData !== null ? (
          <>
            <i className="fa fa-check "></i> Feedback
          </>
        ) : (
          "Feedback"
        )}
      </button>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth="md"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div className="feedback-container">
          <div className="feedback-heading">
            Tell us a bit about your learners English proficiency
          </div>
          <div className="feedback-subheading">
            Please click on the available category to give detailed feedback for
            each
          </div>
          <div className="feedback-attributes-container">
            {points.map((data, index) => {
              const isSelected = selectedPoints.find((point) => {
                if (point === data) return true;
              });
              return (
                <div
                  key={index}
                  className="feedback-points"
                  style={{
                    background: isSelected ? "#52C5B6" : "#DBDBDB",
                  }}
                  onClick={() => {
                    if (isSelected) {
                      setSelectedPoints(
                        selectedPoints.filter((point) => point !== data)
                      );
                    } else {
                      setSelectedPoints([...selectedPoints, data]);
                    }
                  }}
                >
                  <span>{formatString(data)}</span>
                  {isSelected ? (
                    <img
                      src="/assets/Feedback/Minus.svg"
                      alt="plus"
                      height="20"
                      width="20"
                    />
                  ) : (
                    <img
                      src="/assets/Feedback/Plus.svg"
                      alt="plus"
                      height="20"
                      width="20"
                    />
                  )}
                </div>
              );
            })}
          </div>
          {selectedPoints.length === 0 && (
            <div className="feedback-middle-empty-container"></div>
          )}
          <div className="feedback-points-details-container">
            {selectedPoints
              .filter(
                (point) =>
                  point === "non_verbal" ||
                  point === "verbal_speech" ||
                  point === "fundamentals"
              )
              .map((data, index) => (
                <Fragment key={index}>
                  <div className="topic-heading">{formatString(data)}</div>
                  <div className="feedback-options-container">
                    {feedbackInfo[data].map((subData, subIndex) => {
                      return (
                        <div
                          className="feedback-subAttribute-container"
                          key={subIndex}
                        >
                          <div className="feedback-subAttribute-title">
                            {formatString(Object.keys(subData)[0])}
                          </div>
                          <StarRating
                            feedbackInfo={feedbackInfo}
                            setFeedbackInfo={setFeedbackInfo}
                            data={data}
                            subIndex={subIndex}
                            subData={subData}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div
                    className="feedback-subheading"
                    style={{ margin: "10px", textAlign: "left" }}
                  >
                    *Please click on the Star to finalize your rating
                  </div>
                </Fragment>
              ))}
          </div>

          <div className="feedback-points-details-container">
            {selectedPoints.includes("other") && (
              <>
                <div className="other-feedback-heading">Other Feedback</div>
                <div className="other-feedback-subheading">
                  Give a constructive feedback
                </div>
                <div className="feedback-textarea">
                  <textarea
                    id="outlined-multiline-static"
                    name="multiline-textarea"
                    rows="4"
                    placeholder="Type Here..."
                    value={feedbackText}
                    onChange={(e) => setFeedbackText(e.target.value)}
                  ></textarea>
                </div>
              </>
            )}
            {error ? <Alert severity="error">{error}</Alert> : ""}
            <div className="feedback-note">
              Note: For every feedback attribute, please rate atleast one sub
              attribute.
            </div>
            <button
              onClick={() => transformFeedbackData(feedbackInfo)}
              className="feedback-submit-btn"
              disabled={loder}
            >
              {loder ? "Submitting..." : "Submit"}
            </button>
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default CurriculumFeedbackModal;
